import { filter, find, identity, propEq } from "ramda";
import { add, addDays, addMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isBusinessDay } from "../../../utils";
import {
  getIANATimeZoneName,
  getTimeZoneFromConfig,
} from "../../../utils/time-zone";

import {
  HIDE_MODAL_CONNECTOR_SELECTOR,
  INIT_SCHEDULER,
  INIT_SCHEDULER_FROM_PROFILE,
  SET_SCHEDULER_DISPLAY,
  LOAD_CONFIGURATION_SUCCESS,
  SET_SCHEDULER_DAY,
  SET_SCHEDULER_SLOT,
} from "../../../actions";
import {
  CONNECTORS_DEFAULT_WEEKEND_DAYS,
  PROFILE_SPECIFIC_BUS,
  SCHEDULER_OPTION_DAYS,
  SCHEDULER_SLOT_BUFFER_MINUTES,
} from "../../../utils/constants";

const INITIAL_STATE = {
  day: null,
  options: [],
  schedulerDisplay: false,
  slot: null,
};

const getMilitaryHour = (date) => date.getHours() * 100 + date.getMinutes();

const getOptionsForNextBusinessDays = (
  numberOfDays,
  slots,
  timeZone,
  weekendDays
) => {
  let currentDate = utcToZonedTime(new Date(), timeZone);
  currentDate.setHours(0, 0, 0, 0);

  let businessDays = 0;
  let optionsList = [];

  const currentBufferedMilitaryHour = getMilitaryHour(
    addMinutes(
      utcToZonedTime(new Date(), timeZone),
      SCHEDULER_SLOT_BUFFER_MINUTES
    )
  );

  const getHoursFromMilitaryTime = (militaryTime) =>
    militaryTime.length === 4
      ? militaryTime.substr(0, 2)
      : militaryTime.substr(0, 1);

  const getMinutesFromMilitaryTime = (militaryTime) => militaryTime.substr(-2);

  const createSlotsListEntries = (currentDateMarket, listCurrentSlots) =>
    listCurrentSlots.map((slot) => {
      const slotStartTimeMarket = add(currentDateMarket, {
        hours: getHoursFromMilitaryTime(slot[0].toString(10)),
        minutes: getMinutesFromMilitaryTime(slot[0].toString(10)),
      });
      const slotStartTimeMarketTimestamp = slotStartTimeMarket
        .getTime()
        .toString();
      const slotStartTimeUtc = zonedTimeToUtc(slotStartTimeMarket, timeZone);
      const slotStartTimeUser = utcToZonedTime(
        slotStartTimeUtc,
        getIANATimeZoneName()
      );

      const slotEndTimeMarket = add(currentDateMarket, {
        hours: getHoursFromMilitaryTime(slot[1].toString(10)),
        minutes: getMinutesFromMilitaryTime(slot[1].toString(10)),
      });
      const slotEndTimeUtc = zonedTimeToUtc(slotEndTimeMarket, timeZone);
      const slotEndTimeUser = utcToZonedTime(
        slotEndTimeUtc,
        getIANATimeZoneName()
      );

      const slotDayUser = utcToZonedTime(
        slotStartTimeUtc,
        getIANATimeZoneName()
      );
      slotDayUser.setHours(0, 0, 0, 0);
      const slotDayUserTimestamp = slotDayUser.getTime().toString();

      return {
        slot,
        slotDayUserTimestamp,
        slotEndTimeMarket,
        slotEndTimeUser,
        slotStartTimeMarket,
        slotStartTimeMarketTimestamp,
        slotStartTimeUser,
      };
    });

  const startDate = currentDate;

  while (businessDays < numberOfDays) {
    if (isBusinessDay(currentDate, weekendDays)) {
      if (startDate === currentDate) {
        const slotsAvailable = slots.filter(
          ([minTime]) => currentBufferedMilitaryHour < minTime
        );

        if (slotsAvailable.length > 0) {
          optionsList = [
            ...optionsList,
            ...createSlotsListEntries(currentDate, slotsAvailable),
          ];
          businessDays += 1;
        }
      } else {
        optionsList = [
          ...optionsList,
          ...createSlotsListEntries(currentDate, slots),
        ];
        businessDays += 1;
      }
    }

    currentDate = addDays(currentDate, 1);
  }

  return optionsList;
};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case HIDE_MODAL_CONNECTOR_SELECTOR:
      return {
        ...state,
        schedulerDisplay: false,
      };

    case SET_SCHEDULER_DAY: {
      // Set new day and use its first option as slot
      const { day, businessUnit } = payload;
      const availableSlots = filter(propEq("slotDayUserTimestamp", day))(
        state.options[businessUnit]
      );

      return {
        ...state,
        day,
        slot: availableSlots[0],
      };
    }

    case SET_SCHEDULER_SLOT: {
      const { slot, businessUnit } = payload;

      const newSlot = find(propEq("slotStartTimeMarketTimestamp", slot))(
        state.options[businessUnit]
      );

      return {
        ...state,
        slot: newSlot,
      };
    }

    case INIT_SCHEDULER: {
      const bu = payload;

      if (!state.options[bu]) {
        return state;
      }

      return {
        ...state,
        day: state.options[bu][0].slotDayUserTimestamp,
        slot: state.options[bu][0],
      };
    }

    case INIT_SCHEDULER_FROM_PROFILE: {
      const profile = payload;

      if (!state.options[PROFILE_SPECIFIC_BUS[profile]]) {
        return state;
      }

      return {
        ...state,
        day:
          state.options[PROFILE_SPECIFIC_BUS[profile]][0].slotDayUserTimestamp,
        slot: state.options[PROFILE_SPECIFIC_BUS[profile]][0],
      };
    }

    case SET_SCHEDULER_DISPLAY: {
      return {
        ...state,
        schedulerDisplay: payload,
      };
    }

    case LOAD_CONFIGURATION_SUCCESS: {
      const { featureToggles } = payload.configuration;

      if (!featureToggles || !featureToggles.showConnectors) {
        return state;
      }

      const timeZoneData = featureToggles.showConnectors?.timeZone;
      const weekendDays =
        featureToggles.showConnectors?.weekendDays ||
        CONNECTORS_DEFAULT_WEEKEND_DAYS;

      const options = Object.entries(featureToggles.showConnectors).reduce(
        (acc, [key, { callBack }]) => {
          if (callBack) {
            return {
              ...acc,
              [key]: getOptionsForNextBusinessDays(
                SCHEDULER_OPTION_DAYS,
                callBack.slots,
                getTimeZoneFromConfig(timeZoneData, key),
                weekendDays
              ),
            };
          }

          return acc;
        },
        state.options
      );

      return {
        ...state,
        options,
      };
    }

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
