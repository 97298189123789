import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import MultiStepForm from ".";
import * as reducers from "../../reducers";

const mapStateToProps = (state) => ({
  hasUserInteraction: reducers.hasUserInteraction(state),
});

const mapDispatchToProps = {};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MultiStepForm);
