import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";
import { DhlText } from "@dhl-official/react-library";
import { Checkmark as IconCheckmark } from "@dhl-official/icons";
import {
  PROFILES,
  STEP_FLOW,
  STEPS,
  STYLES_VIEWPORTS,
} from "../../../utils/constants";
import { isFSLikeProfile } from "../../../utils";

// #region Component Styles
const INDICATOR_SIZE = "36px";

const OuterContainer = styled.div`
  background-color: var(--dui-color-white-500);
  display: block;
  width: 100%;
`;

const Container = styled.ol`
  background-color: var(--dui-color-white-500);
  display: flex;
  justify-content: center;
  list-style: none;
  margin: var(--dui-size-space-7x) auto 0;
  padding-bottom: calc(var(--dui-size-space-7x) * 2);
  width: max-content;
`;

const ListItem = styled.li`
  align-items: flex-start;
  display: flex;
  flex: 1;
`;

const Step = styled.div.attrs({
  className: "automation-multistepForm-indicator",
})`
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  padding: 0 var(--dui-size-space-10x);
  position: relative;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    margin: 0 var(--dui-size-space-3x);
  }
`;

const Label = styled(DhlText).attrs({ isParagraph: true, size: "sm" })`
  & p {
    margin: 3px 0 0 0;
    text-align: center;
  }
`;

const Indicator = styled.div`
  align-items: center;
  border-radius: 50%;
  border: solid 2px var(--dui-color-gray-500);
  display: flex;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  font-weight: var(--dui-font-weight-bold);
  height: ${INDICATOR_SIZE};
  justify-content: center;
  transition: background-color var(--dui-duration-default),
    color var(--dui-duration-default);
  width: ${INDICATOR_SIZE};
`;

const StyledCheckmark = styled(IconCheckmark)`
  fill: var(--dui-color-white-500);
  height: 24px;
  width: 24px;
`;

const getStyleForStep = ($isActive, $isCurrentStep) => {
  if ($isCurrentStep) {
    return css`
      ${Indicator} {
        background-color: var(--dui-color-white-500);
        border-color: var(--dui-color-black-400);
        color: var(--dui-color-black-400);
        font-weight: 600;
      }

      ${Label} {
        & p {
          color: var(--dui-color-black-400);
          font-weight: 600;
        }
      }
    `;
  }

  return $isActive
    ? css`
        ${Indicator} {
          color: var(--dui-color-white-500);
          border-color: var(--dui-color-green-400);
          background-color: var(--dui-color-green-400);
        }
        ${Indicator}:after {
          content: "";
          position: absolute;
          left: 56px;
          bottom: 16px;
          height: 2px;
          width: 100%;
          background-color: var(--dui-color-green-400);
        }
      `
    : css`
        ${Indicator} {
          background-color: var(--dui-color-white-500);
          color: var(--dui-color-gray-600);
        }
      `;
};

const StepContainer = styled.div`
  ${({ $isActive, $isCurrentStep }) =>
    getStyleForStep($isActive, $isCurrentStep)};
`;

// #endregion

const CONTENT_IDS = {
  [STEPS.PRODUCT]: "Product",
  [STEPS.PRODUCT_DETAILS]: "ProductDetails",
  [STEPS.SERVICES]: "Services",
  [STEPS.CONTACT_FORM]: "ContactForm",
};

const getArrayFromNumber = (numberOfSteps) =>
  // eslint-disable-next-line prefer-spread
  Array.apply(null, { length: numberOfSteps }).map(Number.call, Number);

const StepIndicator = ({
  activeStepNumber,
  contentPrefix,
  numberOfSteps,
  profile,
}) => {
  const iterableSteps = useMemo(() => getArrayFromNumber(numberOfSteps), [
    numberOfSteps,
  ]);

  const getStepLabelId = (step) =>
    /**
     * Why two different ways to store the labels in the CMS (by index/by step id)?
     * In regular FS, the steps always have the same index. In e.g. FSX though,
     * a step can appear on different index positions, hence we have to store the labels
     * by step id, not index.
     * For backwards-compatibility, regular FS labels will have to keep the index method for now.
     */
    isFSLikeProfile(profile)
      ? `FS2.RegularShipment.step${step}Indicator`
      : `${contentPrefix}.RegularShipment.step${
          CONTENT_IDS[STEP_FLOW[profile][step - 1]]
        }Indicator`;

  return (
    <OuterContainer>
      <Container>
        {iterableSteps.map((item, index) => {
          const step = index + 1;
          const isActive = step < activeStepNumber;
          const isCurrentStep = step === activeStepNumber;

          return (
            <ListItem
              aria-hidden={!(step === activeStepNumber)}
              key={`${item}listItem`}
            >
              <StepContainer
                $isActive={isActive}
                $isCurrentStep={isCurrentStep}
              >
                <Step>
                  <Indicator
                    data-testid={`step-indicator-step-${step}`}
                    aria-hidden="true"
                  >
                    {isActive ? <StyledCheckmark /> : step}
                  </Indicator>
                </Step>
                <Label dataTestid={`step-indicator-label-${step}`}>
                  <FormattedMessage
                    id={getStepLabelId(step)}
                    values={{
                      br: <br />,
                    }}
                  />
                </Label>
              </StepContainer>
            </ListItem>
          );
        })}
      </Container>
    </OuterContainer>
  );
};

StepIndicator.propTypes = {
  /** Which is the current step index? Keep in mind this is indexed from 1 */
  activeStepNumber: PropTypes.number,
  contentPrefix: PropTypes.string.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
};

StepIndicator.defaultProps = {
  activeStepNumber: 1,
};

export default memo(StepIndicator);
