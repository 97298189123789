import { defineMessages } from "react-intl";

export default defineMessages({
  headline: {
    id: "FS2.RegularShipment.TT_CSRedirect.headline",
    defaultMessage: "",
  },
  TrackAndTrace_Label: {
    id: "FS2.RegularShipment.TT_CSRedirect.TrackAndTrace_Label",
    defaultMessage: "",
  },
  TrackAndTrace_URL: {
    id: "FS2.RegularShipment.TT_CSRedirect.TrackAndTrace_URL",
    defaultMessage: "",
  },
  CustomerService_Label: {
    id: "FS2.RegularShipment.TT_CSRedirect.CustomerService_Label",
    defaultMessage: "",
  },
  CustomerService_URL: {
    id: "FS2.RegularShipment.TT_CSRedirect.CustomerService_URL",
    defaultMessage: "",
  },
});
