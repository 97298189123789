import { memo } from "react";
import { connect } from "react-redux";
import StepNavigator from ".";
import * as reducers from "../../../reducers";

const mapStateToProps = (state) => ({
  abTesting: reducers.getAbTesting(state),
  connectorsBU: reducers.getConnectorsBU(state),
  isNextStepEnabled: reducers.isNextStepEnabled(state),
  isWaitingForEndpoint: reducers.getIsLoadingGotLead(state),
  shouldHidePreviousStep:
    reducers.isFirstStep(state) || reducers.isThankYouStep(state),
  shouldHideProceedStep: reducers.getShouldHideProceedStep(state),
  shouldShowConnectorsStep: reducers.getShouldShowConnectorsStep(state),
  step: reducers.getActiveStepNumber(state),
  theme: reducers.getTheme(state),
});

export default memo(connect(mapStateToProps, null)(StepNavigator));
