import styled from "styled-components";
import { DhlLink, DhlText } from "@dhl-official/react-library";

export const Container = styled.div`
  text-align: center;
  padding-bottom: calc(var(--dui-size-space-7x) * 2);
`;

export const Headline = styled(DhlText).attrs({
  weight: "700",
  size: "sm",
})`
  display: block;
`;
export const RedirectionMessage = styled(DhlText).attrs({
  size: "sm",
})`
  display: block;
`;

export const Link = styled(DhlLink).attrs({
  rel: "noopener noreferrer",
  fontSize: "sm",
  weight: "700",
})``;
