import { deburr, escapeRegExp } from "lodash-es";
import { complement, compose, head, prop } from "ramda";
import AC from "../../../../utils/functional/auto-complete";
import FF from "../../../../utils/functional/form-field";
import RD from "../../../../utils/functional/remote-data";
import {
  QUERY_COUNTRY,
  SELECT_COUNTRY,
  SET_CONTACT_INFORMATION,
  SET_COUNTRY,
  VALIDATE_COUNTRY,
  VALIDATE_CONTACT_INFORMATION,
  SET_VALIDATED_CONTACT_FORM,
} from "../../../../actions";
import * as reducers from "../../..";

const normalizeValueForSearch = (input) => deburr(input.trim().toLowerCase());

const setValidatedContactForm = (payload) => ({
  payload,
  type: SET_VALIDATED_CONTACT_FORM,
});

const validateCountry = (payload) => ({
  payload,
  type: VALIDATE_COUNTRY,
});

const setCountry = (payload) => ({
  payload,
  type: SET_COUNTRY,
});

const queryCountry = (payload) => ({
  payload,
  type: QUERY_COUNTRY,
});

const selectCountry = (country, countryCallingCode = "") => ({
  type: SELECT_COUNTRY,
  payload: {
    country,
    countryCallingCode,
  },
});

const sortCountries = (countries, filter) => {
  const firstCountries = [];
  const lastCountries = [];

  const byName = ([, a], [, b]) => a.localeCompare(b);

  const byLength = ([, a], [, b]) => (filter ? a.length - b.length : 0);

  const hasMatchingInitial = (item) =>
    normalizeValueForSearch(item).indexOf(filter) === 0;

  const sortedCountries = [...countries.sort(byName)];

  sortedCountries.forEach((item) => {
    if (Boolean(filter) && hasMatchingInitial(item[1])) {
      firstCountries.push(item);
      return;
    }

    lastCountries.push(item);
  });

  return [...firstCountries.sort(byLength), ...lastCountries.sort(byLength)];
};

export const selectCountryEffect = (country) => (dispatch, getState) => {
  const countryCallingCodes = reducers.getCountryCallingCodes(getState());

  dispatch(selectCountry(country, head(countryCallingCodes[country] || [])));
};

export const queryCountryEffect = (payload) => (dispatch, getState) => {
  dispatch(queryCountry(payload));

  const countries = Object.entries(reducers.getCountries(getState()));

  const normalizedInput = normalizeValueForSearch(payload);
  const countryNameRegex = new RegExp(
    `${escapeRegExp(`${normalizedInput}`)}.*`,
    "i"
  );

  const sortedOptions = sortCountries(
    countries.filter(
      ([id, label]) =>
        normalizeValueForSearch(label).match(countryNameRegex) &&
        !id.includes("synonyms")
    ),
    normalizedInput
  );

  const selectedCountry = sortedOptions.find(
    ([, label]) => normalizeValueForSearch(label) === normalizedInput
  );

  const optionsValues = sortedOptions.map(([id, value]) => ({
    id,
    value,
  }));

  if (selectedCountry && sortedOptions.length === 1) {
    dispatch(selectCountryEffect(selectedCountry[0]));

    return;
  }

  dispatch(setCountry(AC.unselected(payload, optionsValues)));
};

export const validateContactInformation = (payload) => ({
  payload,
  type: VALIDATE_CONTACT_INFORMATION,
});

export const validateCountryEffect = (payload) => (dispatch, getState) => {
  dispatch(validateCountry(payload));

  const countries = Object.keys(reducers.getCountries(getState()));
  const contactInformation = reducers.getContactInformation(getState());
  const inputCountry = compose(
    AC.getValue,
    FF.getValue,
    prop("country")
  )(contactInformation);

  if (countries.includes(inputCountry)) {
    dispatch(selectCountryEffect(inputCountry));
    return;
  }

  dispatch(validateContactInformation(payload));
};

export const setContactInformation = (payload) => ({
  payload,
  type: SET_CONTACT_INFORMATION,
});

const validateField = (field) => {
  if (FF.isFormField(field)) {
    return FF.validate(field);
  }

  if (RD.isRemoteData(field)) {
    return RD.map(FF.validate)(field);
  }

  return field;
};

const getInvalidFields = (fieldSet) => {
  const fieldsList = Object.values(fieldSet);
  const invalidFields = [];

  for (let i = 0; i < fieldsList.length; i += 1) {
    const field = fieldsList[i];

    if (FF.isFormField(field) && FF.isInvalid(field)) {
      const { id } = FF.getMeta(field);
      invalidFields.push(id);
    }

    if (
      RD.isRemoteData(field) &&
      RD.withDefault(true, RD.map(complement(FF.isValid), field))
    ) {
      const { id } = RD.withDefault("", RD.map(FF.getMeta, field));
      invalidFields.push(id);
    }
  }

  return invalidFields;
};

export const validateContactFormEffect = () => (
  dispatch,
  getState,
  { document }
) => {
  const formFields = Object.entries(reducers.getContactInformation(getState()));
  const validatedFormFields = formFields.reduce(
    (form, [id, field]) => ({
      ...form,
      [id]: validateField(field),
    }),
    {}
  );

  dispatch(setValidatedContactForm(validatedFormFields));

  const invalidFields = getInvalidFields(validatedFormFields);

  if (invalidFields.length > 0) {
    document
      .querySelector(invalidFields.map((id) => `[name='${id}']`).join())
      ?.focus();
    return false;
  }

  return true;
};
