import { identity } from "ramda";
import {
  LOAD_TRANSLATION_FAILURE,
  LOAD_TRANSLATION_SUCCESS,
  LOAD_TRANSLATION,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_TRANSLATION:
      return {
        ...state,
        [payload]: RD.loading(),
      };

    case LOAD_TRANSLATION_SUCCESS:
      return {
        ...state,
        [payload.locale]: RD.success(payload.messages),
      };

    case LOAD_TRANSLATION_FAILURE:
      return {
        ...state,
        [payload.locale]: RD.error(payload.error),
      };

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
