import { combineReducers } from "redux";
import stepsReducer, * as steps from "./steps";
import modalReducer, * as modal from "./modal";
import phoneNumberValidationReducer, * as phoneNumberValidation from "./phone-number-validation";
import profileReducer, * as profile from "./profile";
import integrationReducer, * as integration from "./integration";
import qualifierReducer, * as qualifier from "./qualifier";
import contentDirectionReducer, * as contentDirection from "./contentDirection";
import countryCallingCodesReducer, * as countryCallingCodes from "./country-calling-codes";
import abTestingReducer, * as abTesting from "./ab-testing";
import themeReducer, * as theme from "./theme";

export default (
  _abTesting,
  _profile,
  _integration,
  _qualifier,
  _contentDirection,
  _theme
) =>
  combineReducers({
    abTesting: abTestingReducer(_abTesting),
    contentDirection: contentDirectionReducer(_contentDirection),
    modal: modalReducer,
    countryCallingCodes: countryCallingCodesReducer,
    integration: integrationReducer(_integration),
    phoneNumberValidation: phoneNumberValidationReducer,
    profile: profileReducer(_profile),
    qualifier: qualifierReducer(_qualifier),
    steps: stepsReducer,
    theme: themeReducer(_theme),
  });

export const getActiveStep = (state) => steps.getActiveStep(state.steps);

export const getNumberOfSteps = (state) => steps.getNumberOfSteps(state.steps);

export const getStepsHistory = (state) => steps.getStepsHistory(state.steps);

export const getShownModal = (state) => modal.get(state.modal);

export const getCountryCallingCodes = (state) =>
  countryCallingCodes.get(state.countryCallingCodes);

export const getExposedPhoneNumberValidation = (state) =>
  phoneNumberValidation.get(state.phoneNumberValidation);

export const getProfile = (state) => profile.get(state.profile);

export const getIntegration = (state) => integration.get(state.integration);

export const getQualifier = (state) => qualifier.get(state.qualifier);

export const getContentDirection = (state) =>
  contentDirection.get(state.contentDirection);

export const getAbTesting = (state) => abTesting.get(state.abTesting);

export const getTheme = (state) => theme.get(state.theme);
