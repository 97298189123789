/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */

import thunk from "redux-thunk";
import { applyMiddleware, compose, createStore } from "redux";
import { getFirstConsistentlyInteractive } from "tti-polyfill";
import { MAIN_FORM_ID } from "../utils/constants";
import reducers from "../reducers";
import api from "../utils/api";

const getPerfomanceAPI = () =>
  window.performance ||
  window.webkitPerformance ||
  window.msPerformance ||
  window.mozPerformance;

const createDecibelIntegration = () => {
  const formId = `#${MAIN_FORM_ID}`;
  const withDelay = (callback) => setTimeout(callback, 10);

  if (window.decibelInsight) {
    return {
      notifyFormRefresh: () =>
        withDelay(() => window.decibelInsight.indexForms()),

      notifyFormSubmit: () =>
        withDelay(() => window.decibelInsight.formSubmitted(formId)),
    };
  }

  return {
    notifyFormRefresh: () => console.log("Decibel Step Notified"),
    notifyFormSubmit: () => console.log("Decibel Form Notified"),
  };
};

const configureStore = ({
  abTesting,
  contentDirection,
  integration,
  pageNameLegacy,
  profile,
  qualifier,
  theme,
  trackingId,
  version,
}) => {
  const isDevelopment = process.env.NODE_ENV !== "production";

  const composeEnhancers =
    (isDevelopment &&
      typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  return createStore(
    reducers({
      abTesting,
      contentDirection,
      integration,
      pageNameLegacy,
      profile,
      qualifier,
      theme,
      trackingId,
      version,
    }),
    undefined,
    composeEnhancers(
      applyMiddleware(
        thunk.withExtraArgument({
          API: api,
          Date,
          decibel: createDecibelIntegration(),
          document,
          getFirstConsistentlyInteractive,
          getLibPhoneNumber: () => import("libphonenumber-js"),
          performanceAPI: getPerfomanceAPI(),
          PerformanceObserver: window.PerformanceObserver,
          window,
        })
      )
    )
  );
};

export default configureStore;
