import { useProfile } from "./useProfile";
import { STEP_NUMBERS, DEFAULT_STEP_NUMBER } from "../utils/constants";
import { useNumberOfSteps } from "./useNumberOfSteps";
import { useStep } from "./useStep";

export const useStepNumber = () => {
  const profile = useProfile();
  const activeStep = useStep();
  const stepNumbers = useNumberOfSteps();
  return stepNumbers === 1
    ? DEFAULT_STEP_NUMBER
    : STEP_NUMBERS?.[profile]?.[activeStep] || DEFAULT_STEP_NUMBER;
};

export default useStepNumber;
