import { identity } from "ramda";
import {
  LOAD_CONFIGURATION,
  LOAD_CONFIGURATION_SUCCESS,
  LOAD_CONFIGURATION_FAILURE,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_CONFIGURATION:
      return {
        ...state,
        [payload]: RD.loading(),
      };

    case LOAD_CONFIGURATION_SUCCESS:
      return {
        ...state,
        [payload.country]: RD.success({
          ...payload.configuration,
          settings: payload.settings,
        }),
      };

    case LOAD_CONFIGURATION_FAILURE:
      return {
        ...state,
        [payload.country]: RD.error(payload.error),
      };

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
