import { compose } from "ramda";
import { memo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import CallbackRemoteScheduler from ".";
import * as actions from "../../../reducers/actions";
import * as reducers from "../../../reducers";
import RD from "../../../utils/functional/remote-data";

const mapStateToProps = (state, { businessUnit }) => {
  const { day, options, slot, submitError } = reducers.getCallbackRemote(state);

  return {
    submitError,
    options: RD.withDefault({}, options[businessUnit]),
    selectedDay: day,
    selectedSlot: slot,
  };
};

const mapDispatchToProps = (dispatch, { businessUnit }) => ({
  setSchedulerDay: (day) =>
    dispatch(actions.setCallbackRemoteDay(day, businessUnit)),
  setSchedulerSlot: (payload) =>
    dispatch(actions.setCallbackRemoteSlot(payload)),
});

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CallbackRemoteScheduler);
