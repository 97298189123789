import { memo } from "react";
import { compose } from "ramda";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../../../reducers/actions";
import CustomAutoCompleteField from ".";

const mapDispatchToProps = {
  queryCountry: actions.queryCountryEffect,
  selectCountry: actions.selectCountryEffect,
};

export default compose(
  memo,
  connect(null, mapDispatchToProps),
  injectIntl
)(CustomAutoCompleteField);
