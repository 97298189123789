import { identity } from "ramda";
import RD from "../../../utils/functional/remote-data";
import {
  LOAD_CONFIGURATION,
  LOAD_CONFIGURATION_SUCCESS,
} from "../../../actions";
import { DEFAULT_LEAD_META_VALUES } from "../../../utils/constants";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_CONFIGURATION:
      return RD.loading();

    case LOAD_CONFIGURATION_SUCCESS: {
      const { configuration } = payload;

      const configLeadMetaValues = configuration?.meta?.leadMetaValues || {};

      const leadMetaValues = Object.entries(DEFAULT_LEAD_META_VALUES).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            configLeadMetaValues[key] != null
              ? configLeadMetaValues[key]
              : value,
        }),
        {}
      );

      return RD.success(leadMetaValues);
    }

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
