import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DhlCard } from "@dhl-official/react-library";
import CheckboxField from "./Checkbox";

// #region Component Styles
const Container = styled(DhlCard)`
  display: block;
  height: 100%;

  & .card-container {
    height: 100%;
  }

  & .card-body {
    border: solid 1px transparent;
    border-color: ${({ hasError }) =>
      hasError ? `var(--dui-color-red-500)` : undefined};
    border-radius: var(--dui-size-radius-md);
    height: 100%;
    padding-bottom: var(--dui-size-space-3x);
    padding-left: var(--dui-size-space-7x);
    padding-right: var(--dui-size-space-7x);
    padding-top: var(--dui-size-space-3x);
    position: relative;
    user-select: none;

    &:focus-within {
      border-color: var(--dui-color-black-400);
      box-shadow: 0 0 0 2px var(--dui-color-black-400);
    }
  }
`;

const HiddenLabel = styled.label.attrs({
  "aria-hidden": true,
})`
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Checkbox = styled(CheckboxField)`
  margin: auto 0.5rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0.5rem;
`;
// #endregion

const CardSelectable = ({
  ariaDescribedBy,
  ariaLabel,
  children,
  dataTestid,
  dataTrackingKeyPath,
  feedback,
  isChecked,
  isRequired,
  name,
  onChange,
  reference,
  value,
}) => {
  const hasError = feedback && feedback.hasError;

  return (
    <Container data-testid={dataTestid} hasError={hasError} ref={reference}>
      <HiddenLabel htmlFor={value} />

      <Checkbox
        ariaDescribedBy={ariaDescribedBy}
        ariaInvalid={hasError}
        ariaLabel={ariaLabel}
        dataTestid={value}
        dataTracking={dataTrackingKeyPath}
        id={value}
        isChecked={isChecked}
        isRequired={isRequired}
        name={name}
        onChange={onChange}
        value={value}
      />
      {children}
    </Container>
  );
};

CardSelectable.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  dataTestid: PropTypes.string,
  dataTrackingKeyPath: PropTypes.string,
  feedback: PropTypes.object,
  isChecked: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  reference: PropTypes.object,
  value: PropTypes.string.isRequired,
};

CardSelectable.defaultProps = {
  isRequired: false,
};

export default memo(CardSelectable);
