import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import * as actions from "../../reducers/actions";
import LoadingSkeleton from ".";

const mapDispatchToProps = {
  trackFirstMeaningfulPaint: actions.trackFirstMeaningfulPaintEffect,
};

export default compose(
  memo,
  connect(null, mapDispatchToProps)
)(LoadingSkeleton);
