import { memo } from "react";
import { connect } from "react-redux";
import { compose } from "ramda";
import ShipmentProductCard from ".";
import * as actions from "../../../../reducers/actions";

const mapDispatchToProps = {
  onSetIsChecked: actions.setShipmentProduct,
};

export default compose(
  memo,
  connect(undefined, mapDispatchToProps)
)(ShipmentProductCard);
