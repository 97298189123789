import { prop, compose, map } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import * as actions from "../../reducers/actions";
import * as reducers from "../../reducers";
import RD from "../../utils/functional/remote-data";
import Tracking from ".";
import { MODALS_FOR_TRACKING } from "../../utils/constants";

const getBuIds = compose(map(prop("id")), RD.withDefault([]));

const convertProductValues = (shipmentProduct) =>
  Object.entries(shipmentProduct).reduce(
    (values, [key, product]) => ({
      ...values,
      [key.toLowerCase()]: {
        ...product,
        shipmentScales: product.shipmentScales.map((region) =>
          region.toLowerCase()
        ),
      },
    }),
    {}
  );

const mapStateToProps = (state) => {
  const busThatGotLead = reducers.getBusThatGotLead(state);
  const { shipmentProduct } = reducers.getRequestData(state);
  const shipmentProductForTracking = convertProductValues(shipmentProduct);
  const businessUnitsLead = getBuIds(busThatGotLead);
  const shownModalForTracking = MODALS_FOR_TRACKING.includes(
    reducers.getShownModal(state)
  )
    ? reducers.getShownModal(state)
    : null;
  const shipmentScale = {}; // keeping it for backward compatibility reasons regarding Adobe analytics

  return {
    businessUnitsLead,
    forcedCrossBUs: undefined,
    phoneNumberValidation: reducers.getPhoneNumberValidation(state),
    shipmentProduct: shipmentProductForTracking,
    shipmentScale,
    featureFlags: reducers.getFeatureFlags(state),
    shownModal: shownModalForTracking,
    stepsHistory: reducers.getStepsHistory(state),
    trackingData: reducers.getTracking(state),
    validationStates: reducers.getValidationStates(state),
    version: reducers.getVersion(state),
    withScheduledCallback: reducers.getIsScheduledLead(state),
  };
};

const mapDispatchToProps = {
  pushToDigitalLayer: actions.pushToDigitalLayerEffect,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Tracking));
