import PropTypes from "prop-types";
import {
  DhlCheckbox,
  DhlText,
  DhlValidationFeedback,
} from "@dhl-official/react-library";
import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import styled from "styled-components";
import FF from "../../../../utils/functional/form-field";
import { PARCEL_OPTIONS } from "../../../../utils/constants";

// #region Component Styles
const Container = styled.div.attrs({
  "data-testid": "parcel-urgency-options",
})`
  margin: var(--dui-size-space-10x) 0;
`;

const Info = styled(DhlText).attrs({ isParagraph: true, size: "sm" })`
  & p {
    margin-bottom: var(--dui-size-space-7x);
  }
`;

const Item = styled.div`
  margin-bottom: var(--dui-size-space-7x);
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Option = styled(DhlCheckbox).attrs({
  size: "md",
})`
  & > div > label.md {
    height: auto;
  }
`;

const OptionContent = styled.div``;

const Title = styled(DhlText).attrs({
  isParagraph: true,
  weight: 700,
})`
  & p {
    margin: 0;
  }
`;

const SubTitle = styled(Title).attrs({ size: "sm", weight: 400 })`
  & p {
    color: var(--dui-color-gray-600);
    margin: 0;
  }
`;
// #endregion

const options = Object.values(PARCEL_OPTIONS);
const translations = {
  [PARCEL_OPTIONS.STANDARD]: {
    title: "FS2.CardShipmentProduct.parcelOptionsStandard",
    subtitle: "FS2.CardShipmentProduct.parcelOptionsStandardExplanation",
  },
  [PARCEL_OPTIONS.EXPEDITED]: {
    title: "FS2.CardShipmentProduct.parcelOptionsExpedited",
    subtitle: "FS2.CardShipmentProduct.parcelOptionsExpeditedExplanation",
  },
};

const ParcelOptions = ({
  className,
  onChange,
  formField,
  product,
  isInvalid,
}) => {
  const intl = useIntl();
  const selectedOptions = FF.getValue(formField.options);

  return (
    <Container className={className}>
      <Info>
        <FormattedMessage id="FS2.CardShipmentProduct.parcelOptionsInfo" />
      </Info>
      {options.map((option) => {
        const translate = translations[option];
        return (
          <Item key={option}>
            <Option
              dataTestid={`parcelOptions-${option}`}
              checked={selectedOptions[option]}
              name={option}
              onDhlChange={() => onChange(option)}
              value={option}
              validation={isInvalid ? { type: "invalid" } : { type: "valid" }}
            >
              <OptionContent>
                <Title>
                  <FormattedMessage id={translate.title} />
                </Title>
                <SubTitle>
                  <FormattedMessage
                    id={translate.subtitle}
                    values={{
                      br: <br />,
                    }}
                  />
                </SubTitle>
              </OptionContent>
            </Option>
          </Item>
        );
      })}

      {isInvalid && (
        <DhlValidationFeedback
          dataTestid={`shipmentProduct-${product}-parcel-validationMessage`}
          validation={{
            type: "invalid",
            message: intl.formatMessage({
              id: "FS2.Validations.shipmentProductvalueMissing",
            }),
          }}
        />
      )}
    </Container>
  );
};

ParcelOptions.propTypes = {
  className: PropTypes.string,
  product: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formField: PropTypes.object,
  isInvalid: PropTypes.bool,
};

export default ParcelOptions;
