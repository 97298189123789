import { always, compose, path, pathOr, identity } from "ramda";
import FF, {
  Meta as M,
  Validation as V,
} from "../../../utils/functional/form-field";
import AC from "../../../utils/functional/auto-complete";
import {
  COUNTRY_CODES,
  CONTACT_FORM_FIELD_PATTERNS,
} from "../../../utils/constants";
import {
  LOAD_CONFIGURATION_SUCCESS,
  LOAD_CONFIGURATION,
  SELECT_COUNTRY,
  SET_CONTACT_INFORMATION,
  SET_COUNTRY,
  VALIDATE_CONTACT_INFORMATION,
  SET_VALIDATED_CONTACT_FORM,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const COPY_ID_PREFIX = "FS2.Validations";

const COPY_VALUE_MISSING = `${COPY_ID_PREFIX}.regularShipmentvalueMissing`;
const COPY_COUNTRY_MISSING = `${COPY_ID_PREFIX}.countryvalueMissing`;
const COPY_EMAIL_PATTERN = `${COPY_ID_PREFIX}.emailpatternMismatch`;
const COPY_PHONE_PATTERN = `${COPY_ID_PREFIX}.phoneNumberpatternMismatch`;
const COPY_PHONE_EXTENSION_PATTERN = `${COPY_ID_PREFIX}.phoneExtensionpatternMismatch`;
const COPY_POSTAL_CODE_PATTERN = `${COPY_ID_PREFIX}.postalCodePatternMismatch`;

const makeRequiredContactField = (id) =>
  FF.pristine(M.create([V.required(COPY_VALUE_MISSING)], id), "");

const makeRequiredPatternContactField = (id) =>
  FF.pristine(
    M.create(
      [
        V.required(COPY_VALUE_MISSING),
        V.pattern(
          `${COPY_ID_PREFIX}.${id}PatternMismatch`,
          CONTACT_FORM_FIELD_PATTERNS[id]
        ),
      ],
      id
    ),
    ""
  );

/**
 * The `RemoteData` fields have their initialization deferred. They are
 * only initialized when we have more information from the country
 * configuration as it impacts their default values and validation rules.
 */
const INITIAL_STATE = {
  address: makeRequiredPatternContactField("address"),
  city: makeRequiredPatternContactField("city"),
  comments: FF.valid(M.create(undefined, "comments"), ""),
  company: makeRequiredPatternContactField("company"),
  countryCallingCode: makeRequiredContactField("countryCallingCode"),
  country: FF.pristine(
    M.create(
      [
        V.required(COPY_VALUE_MISSING),
        V.inDataset(COPY_COUNTRY_MISSING, COUNTRY_CODES, "value"),
      ],
      "country"
    ),
    AC.unselected("", [])
  ),
  email: FF.pristine(
    M.create(
      [
        V.required(COPY_VALUE_MISSING),
        V.pattern(COPY_EMAIL_PATTERN, CONTACT_FORM_FIELD_PATTERNS.email),
      ],
      "email"
    ),
    ""
  ),
  firstName: makeRequiredPatternContactField("firstName"),
  lastName: makeRequiredPatternContactField("lastName"),
  phoneExtension: FF.valid(
    M.create(
      [
        V.pattern(
          COPY_PHONE_EXTENSION_PATTERN,
          CONTACT_FORM_FIELD_PATTERNS.phoneExtension
        ),
      ],
      "phoneExtension"
    ),
    ""
  ),
  phoneNumber: RD.notAsked(),
  zip: RD.notAsked(),
};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case VALIDATE_CONTACT_INFORMATION: {
      const field = state[payload];

      if (RD.isRemoteData(field)) {
        return {
          ...state,
          [payload]: RD.map(FF.validate)(field),
        };
      }

      return {
        ...state,
        [payload]: FF.validate(field),
      };
    }

    case SET_CONTACT_INFORMATION: {
      const field = state[payload.name];

      if (RD.isRemoteData(field)) {
        return {
          ...state,
          [payload.name]: RD.map(
            compose(FF.validate, FF.map(always(payload.value)))
          )(field),
        };
      }

      return {
        ...state,
        [payload.name]: compose(
          FF.validate,
          FF.map(always(payload.value))
        )(field),
      };
    }

    case LOAD_CONFIGURATION:
      return {
        ...state,
        phoneNumber: RD.loading(),
        zip: RD.loading(),
      };

    case SET_COUNTRY:
      return {
        ...state,
        country: compose(
          FF.chain(FF.unchecked),
          FF.map(always(payload))
        )(state.country),
      };

    case SELECT_COUNTRY:
      return {
        ...state,
        countryCallingCode: compose(
          FF.chain(FF.valid),
          FF.map(always(payload.countryCallingCode))
        )(state.countryCallingCode),
        country: compose(
          FF.chain(FF.valid),
          FF.map(always(AC.selected(payload.country, [])))
        )(state.country),
      };

    case LOAD_CONFIGURATION_SUCCESS: {
      const { configuration } = payload;

      const isZipRequired =
        path(
          ["contactForm", "fields", "postalCode", "required"],
          configuration
        ) !== false;

      const postalCodePattern = pathOr(
        CONTACT_FORM_FIELD_PATTERNS.postalCode,
        ["contactForm", "fields", "postalCode", "pattern"],
        configuration
      );

      const phoneNumberPattern = path(
        ["contactForm", "fields", "phoneNumber", "pattern"],
        configuration
      );

      const validOrPristineFF = isZipRequired ? FF.pristine : FF.valid;

      return {
        ...state,
        phoneNumber: RD.success(
          FF.pristine(
            M.create(
              [
                V.required(COPY_VALUE_MISSING),
                phoneNumberPattern &&
                  V.pattern(COPY_PHONE_PATTERN, phoneNumberPattern),
              ].filter(Boolean),
              "phoneNumber"
            ),
            ""
          )
        ),
        zip: RD.success(
          validOrPristineFF(
            M.create(
              isZipRequired
                ? [
                    V.required(COPY_VALUE_MISSING),
                    V.pattern(COPY_POSTAL_CODE_PATTERN, postalCodePattern),
                  ].filter(Boolean)
                : [V.pattern(COPY_POSTAL_CODE_PATTERN, postalCodePattern)],
              "zip"
            ),
            ""
          )
        ),
      };
    }

    case SET_VALIDATED_CONTACT_FORM:
      return payload;

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
