export const trackContentInteraction = (content) => {
  document.dispatchEvent(
    new CustomEvent("dhl_utf_contentInteraction", {
      detail: {
        content,
      },
    })
  );
};

export const trackConversionInteraction = (detail) => {
  document.dispatchEvent(
    new CustomEvent("dhl_utf_conversionInteraction", {
      detail,
    })
  );
};

export const updatePageName = (pageNameLegacy, section) => {
  if (pageNameLegacy && window?.dataLayer?.page?.pageInfo?.pageNameLegacy) {
    const currentPageNameParts = pageNameLegacy.split("|");
    currentPageNameParts[0] = section
      ? [currentPageNameParts[0], ` ${section} `].join("-")
      : currentPageNameParts[0];
    window.dataLayer.page.pageInfo.pageNameLegacy = currentPageNameParts.join(
      "|"
    );
  }
};

export const trackPageView = (pageNameLegacy, section) => {
  updatePageName(pageNameLegacy, section);
  document.dispatchEvent(new CustomEvent("dhl_utf_pageload"));
};
