import { prop } from "ramda";
import {
  LOAD_CONFIGURATION_SUCCESS,
  POST_LEAD_SUCCESS,
  REMOVE_SHIPMENT_PRODUCT,
  SET_ACTIVE_STEP,
} from "../../../actions";
import {
  DEFAULT_FIRST_STEP,
  DEFAULT_NUMBER_OF_STEPS,
  DEFAULT_PROFILE,
  PROFILE_SPECIFIC_BUS,
  STEPS,
} from "../../../utils/constants";

const INITIAL_STATE = {
  numberOfSteps: DEFAULT_NUMBER_OF_STEPS.FS,
  stepsHistory: [DEFAULT_FIRST_STEP.FS],
};

const getFlexibleFirstStep = (configuration, profile) => {
  const { featureToggles } = configuration;

  if (featureToggles.showIntroPage) {
    return STEPS.INTRO;
  }
  if (featureToggles.showProductDetailsPage) {
    return STEPS.PRODUCT_DETAILS;
  }
  if (featureToggles?.showConnectors?.[PROFILE_SPECIFIC_BUS[profile]]) {
    return STEPS.CONNECTORS;
  }
  return DEFAULT_FIRST_STEP?.[profile] || DEFAULT_FIRST_STEP[DEFAULT_PROFILE];
};

const getFlexibleNumberOfSteps = (configuration, profile) => {
  const { featureToggles } = configuration;

  let numberOfSteps =
    DEFAULT_NUMBER_OF_STEPS?.[profile] ||
    DEFAULT_NUMBER_OF_STEPS[DEFAULT_PROFILE];

  if (featureToggles.showProductDetailsPage) {
    numberOfSteps += 1;
  }
  return numberOfSteps;
};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_ACTIVE_STEP:
      return {
        ...state,
        stepsHistory: [...state.stepsHistory, payload],
      };

    case REMOVE_SHIPMENT_PRODUCT: {
      if (payload.isLastCheckedProduct) {
        return {
          ...state,
          stepsHistory: [...state.stepsHistory, DEFAULT_FIRST_STEP.FS],
        };
      }

      return state;
    }

    case POST_LEAD_SUCCESS: {
      if (payload.status === "SUCCESS") {
        return {
          ...state,
          stepsHistory: [...state.stepsHistory, STEPS.THANK_YOU],
        };
      }

      return state;
    }

    case LOAD_CONFIGURATION_SUCCESS: {
      const { profile, configuration } = payload;

      if (profile !== DEFAULT_PROFILE) {
        return {
          ...state,
          numberOfSteps: getFlexibleNumberOfSteps(configuration, profile),
          stepsHistory: [getFlexibleFirstStep(configuration, profile)],
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export const getNumberOfSteps = prop("numberOfSteps");

export const getStepsHistory = prop("stepsHistory");

export const getActiveStep = (state) => {
  const [last] = state.stepsHistory.slice(-1);

  return last;
};

export default reducer;
