import { invoke } from "lodash-es";
import {
  always,
  complement,
  find,
  compose,
  equals,
  reduce,
  nth,
  flatten,
  head,
  filter,
  map,
  prop,
  propOr,
  propEq,
  sort,
  uniq,
  values as rValues,
} from "ramda";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getIANATimeZoneName, getTimeZoneFromConfig } from "../utils/time-zone";
import { slotToTime, parseDateToOIPFormat, isFSLikeProfile } from "../utils";
import {
  CONNECTORS_DEFAULT_WEEKEND_DAYS,
  CONTENT_DIRECTIONS,
  CONTENT_PREFIXES,
  COUNTRY_KEY_NAMESPACE,
  DEFAULT_CONTENT_PREFIX,
  DEFAULT_STEP_NUMBER,
  PARCEL_OPTIONS,
  PAYLOAD_CONTACT_FORM_TYPES,
  PROFILE_SPECIFIC_BUS,
  STEPS,
  STEP_NUMBERS,
  STEPS_WITHOUT_SCREEN_READER_ANNOUNCEMENT,
  STEPS_WITHOUT_STEP_INDICATOR,
  FILLABLE_CONTACT_FORM_FIELDS,
} from "../utils/constants";
import AC from "../utils/functional/auto-complete";
import FF from "../utils/functional/form-field";
import RD from "../utils/functional/remote-data";
import leadReducer, * as lead from "./lead";
import localizedReducer, * as localized from "./localized";
import metaReducer, * as meta from "./meta";
import uiReducer, * as ui from "./ui";

const getFormFieldValue = (formField) => {
  const fieldValue = FF.getValue(formField);

  return AC.isAutoComplete(fieldValue) ? AC.getValue(fieldValue) : fieldValue;
};

const getExposedPhoneNumberValidation = (state) =>
  ui.getExposedPhoneNumberValidation(state.ui);

const getFormFieldFeedback = FF.case({
  valid: () => ({
    feedbackMessageId: undefined,
    hasError: false,
  }),
  invalid: (_, { rule, ...feedback }) => feedback,
  _: always({}),
});

const decodeRegions = (regions) =>
  Object.keys(regions).filter((region) => !!regions[region]);

const decodeProduct = (shipmentProduct) =>
  Object.entries(FF.getValue(shipmentProduct)).reduce(
    (values, [key, product]) => ({
      ...values,
      [key]: {
        ...product,
        frequency: FF.getValue(product.frequency) || "",
        numberOfShipments:
          parseFloat(FF.getValue(product.numberOfShipments)) || 0,
        shipmentScales: decodeRegions(FF.getValue(product.regions)) || [],
      },
    }),
    {}
  );

const decodeContact = (
  scheduler,
  schedulerDisplay,
  featureFlags,
  contactInformation,
  translations,
  callbackRemote,
  callbackRemoteDisplay
) => {
  const contact = Object.entries(contactInformation).reduce(
    (values, [key, field]) => {
      if (RD.isRemoteData(field)) {
        return RD.case(
          {
            success: (data) => ({
              ...values,
              [key]: getFormFieldValue(data),
            }),
            _: always(values),
          },
          field
        );
      }

      if (FF.isFormField(field)) {
        return {
          ...values,
          [key]: getFormFieldValue(field),
        };
      }

      return values;
    },
    {}
  );

  const hasPhoneExtensionEnabled = compose(
    RD.withDefault(false),
    RD.map(prop("withPhoneExtension"))
  )(featureFlags);

  const getCallbackInfo = () => {
    if (schedulerDisplay && scheduler.slot) {
      const date = new Date(
        parseInt(scheduler.slot.slotStartTimeMarketTimestamp, 10)
      );

      return {
        callbackDate: parseDateToOIPFormat(date),
        callbackStartTime: slotToTime(scheduler.slot.slot[0]),
        callbackEndTime: slotToTime(scheduler.slot.slot[1]),
      };
    }

    if (callbackRemoteDisplay && callbackRemote.day && callbackRemote.slot) {
      return {
        callbackDate: callbackRemote.day,
        callbackStartTime: callbackRemote.slot,
        timeZone: getIANATimeZoneName(),
      };
    }

    return {};
  };

  return {
    ...contact,
    callbackInfo: getCallbackInfo(),
    countryCallingCode: undefined,
    phoneExtension: undefined,

    countryDisplay:
      RD.withDefault({}, translations)[
        `CountryAutocompleteField.${contact.country}`
      ] || contact.country,

    phoneNumber: hasPhoneExtensionEnabled
      ? `${contact.countryCallingCode}${contact.phoneNumber}${contact.phoneExtension}`
      : `${contact.countryCallingCode}${contact.phoneNumber}`,
  };
};

const decodeContactFeedback = (contactInformation) =>
  Object.entries(contactInformation).reduce((values, [key, field]) => {
    if (RD.isRemoteData(field)) {
      return RD.case(
        {
          success: (data) => ({
            ...values,
            [key]: getFormFieldFeedback(data),
          }),
          _: always(values),
        },
        field
      );
    }

    if (FF.isFormField(field)) {
      return {
        ...values,
        [key]: getFormFieldFeedback(field),
      };
    }

    return values;
  }, {});

const decodeProductFeedback = (shipmentProduct) => ({
  shipmentProduct: getFormFieldFeedback(
    FF.map(always(undefined), shipmentProduct)
  ),
  ...Object.entries(FF.getValue(shipmentProduct)).reduce(
    (feedback, [productName, product]) => ({
      ...Object.entries(product).reduce((allFields, [fieldName, field]) => {
        if (fieldName === "checked") {
          return allFields;
        }

        return {
          ...allFields,
          [`shipmentProduct_${productName}_${fieldName}`]:
            fieldName === "numberOfShipments"
              ? getFormFieldFeedback(field)
              : {},
        };
      }, feedback),
    }),
    {}
  ),
});

const reducer = ({
  abTesting,
  contentDirection,
  integration,
  qualifier,
  pageNameLegacy,
  profile,
  theme,
  trackingId,
  version,
}) =>
  combineReducers({
    lead: leadReducer,
    localized: localizedReducer,
    meta: metaReducer(version, trackingId, pageNameLegacy),
    ui: uiReducer(
      abTesting,
      profile,
      integration,
      qualifier,
      contentDirection,
      theme
    ),
  });

export const getLocale = (state) => localized.getLocale(state.localized);

export const getCountry = createSelector(getLocale, (locale) => {
  const [, country] = locale.split("-");

  return country;
});

export const getConfiguration = createSelector(
  [getCountry, (state) => localized.getConfiguration(state.localized)],
  (country, settings) => settings[country] || RD.notAsked()
);

export const getTranslation = createSelector(
  [getLocale, (state) => localized.getTranslation(state.localized)],
  (locale, messages) => messages[locale] || RD.notAsked()
);

export const getCountries = createSelector(
  [getTranslation],
  compose(
    RD.withDefault({}),
    RD.map((messages) =>
      Object.entries(messages).reduce(
        (allCountries, [key, message]) =>
          key.includes(`${COUNTRY_KEY_NAMESPACE}.`)
            ? {
                ...allCountries,
                [key.replace(`${COUNTRY_KEY_NAMESPACE}.`, "")]: message,
              }
            : allCountries,
        {}
      )
    )
  )
);

export const getLanguage = (state) => {
  const locale = localized.getLocale(state.localized);
  const [language] = locale.split("-");

  return language;
};

export const getProductOptions = (state) => lead.getProductOptions(state.lead);

export const getProductFields = (state) => lead.getProductFields(state.lead);

export const getScheduler = (state) => lead.getScheduler(state.lead);

export const getSchedulerDisplay = (state) => {
  const scheduler = lead.getScheduler(state.lead);
  return scheduler.schedulerDisplay;
};

export const getCallbackRemote = (state) => lead.getCallbackRemote(state.lead);

export const getCallbackRemoteDisplay = (state) => {
  const callBackRemote = lead.getCallbackRemote(state.lead);
  return callBackRemote.display;
};

export const getAbTesting = (state) => ui.getAbTesting(state.ui);

export const getProfile = (state) => ui.getProfile(state.ui);

export const getIntegration = (state) => ui.getIntegration(state.ui);

export const getQualifier = (state) => ui.getQualifier(state.ui);

export const getContentDirection = (state) => ui.getContentDirection(state.ui);

export const getTheme = (state) => ui.getTheme(state.ui);

export const isRtlLayout = (state) =>
  ui.getContentDirection(state.ui) === CONTENT_DIRECTIONS.RTL;

export const getContentPrefix = createSelector(getProfile, (profile) => {
  return CONTENT_PREFIXES[profile] || DEFAULT_CONTENT_PREFIX;
});

export const getActiveStep = (state) => ui.getActiveStep(state.ui);

export const getNumberOfSteps = (state) => ui.getNumberOfSteps(state.ui);

export const isSingleStep = (state) => getNumberOfSteps(state) === 1;

export const getActiveStepNumber = createSelector(
  [getActiveStep, getProfile, isSingleStep],
  (activeStep, profile, singleStep) =>
    singleStep
      ? DEFAULT_STEP_NUMBER
      : STEP_NUMBERS?.[profile]?.[activeStep] || DEFAULT_STEP_NUMBER
);

export const getStepsHistory = (state) => ui.getStepsHistory(state.ui);

export const getShownModal = (state) => ui.getShownModal(state.ui);

export const getCountryCallingCodes = (state) =>
  ui.getCountryCallingCodes(state.ui);

export const getOrderedCountryCallingCodesList = createSelector(
  getCountryCallingCodes,
  compose(
    map((countryCallingCode) => {
      return { label: countryCallingCode, value: countryCallingCode };
    }),
    uniq,
    sort(
      (a, b) => parseFloat(a.replace("+", "")) - parseFloat(b.replace("+", ""))
    ),
    map(head),
    rValues
  )
);

export const isFirstStep = (state) => getActiveStepNumber(state) === 1;

export const isThankYouStep = compose(equals(STEPS.THANK_YOU), getActiveStep);

export const shouldHideScreenReaderAnnouncement = (state) =>
  STEPS_WITHOUT_SCREEN_READER_ANNOUNCEMENT.includes(getActiveStep(state)) ||
  (isSingleStep(state) && !isThankYouStep(state));

export const shouldHideStepIndicator = (state) =>
  STEPS_WITHOUT_STEP_INDICATOR.includes(getActiveStep(state)) ||
  (isSingleStep(state) && !isThankYouStep(state));

export const getIsLoadingGotLead = (state) =>
  lead.getIsLoadingGotLead(state.lead);

export const getBusThatGotLead = (state) => lead.getBusThatGotLead(state.lead);

export const getContactInformation = (state) =>
  lead.getContactInformation(state.lead);

export const getFeatureFlags = (state) => meta.getFeatureFlags(state.meta);

export const getLeadMetaValues = (state) => meta.getLeadMetaValues(state.meta);

export const getPageNameLegacy = (state) => meta.getPageNameLegacy(state.meta);

export const getTracking = (state) => meta.getTracking(state.meta);

export const getTrackingId = compose(prop("trackingId"), getTracking);

export const getVersion = (state) => meta.getVersion(state.meta);

export const getRequestData = createSelector(
  [
    getScheduler,
    getSchedulerDisplay,
    getFeatureFlags,
    getProductFields,
    getContactInformation,
    getTranslation,
    getCallbackRemote,
    getCallbackRemoteDisplay,
  ],
  (
    scheduler,
    schedulerDisplay,
    featureFlags,
    shipmentProduct,
    contactInformation,
    translations,
    callbackRemote,
    callbackRemoteDisplay
  ) => ({
    contactInformation: decodeContact(
      scheduler,
      schedulerDisplay,
      featureFlags,
      contactInformation,
      translations,
      callbackRemote,
      callbackRemoteDisplay
    ),
    shipmentProduct: shipmentProduct ? decodeProduct(shipmentProduct) : {},
  })
);

export const getValidationStates = createSelector(
  [getContactInformation, getProductFields],
  (contactInformation, shipmentProduct) => ({
    ...decodeContactFeedback(contactInformation),
    ...(shipmentProduct ? decodeProductFeedback(shipmentProduct) : {}),
    shipmentScale: {},
  })
);

export const getUniqueId = (state) => lead.getUniqueId(state.lead);

export const getPhoneNumberValidation = createSelector(
  getScheduler,
  getSchedulerDisplay,
  getFeatureFlags,
  getExposedPhoneNumberValidation,
  getContactInformation,
  getTranslation,
  (
    scheduler,
    schedulerDisplay,
    featureFlags,
    phoneNumberLibExposed,
    contactInformation,
    translations
  ) => {
    const { country, phoneNumber } = decodeContact(
      scheduler,
      schedulerDisplay,
      featureFlags,
      contactInformation,
      translations
    );

    return RD.case(
      {
        success: (exposed) => {
          let isValid;

          // Wrapping inside try catch as library throws for unsuported countries.
          try {
            isValid = invoke(window, exposed, phoneNumber, country);
          } catch (ex) {
            isValid = false;
          }

          return {
            country,
            isValid,
            length: phoneNumber.length,
          };
        },
        _: always(null),
      },
      phoneNumberLibExposed
    );
  }
);

const normalizeCheckedProducts = compose(
  map(([key, { checked, ...product }]) => ({
    ...product,
    id: key,
    frequency: FF.getValue(product.frequency),
    numberOfShipments: FF.getValue(product.numberOfShipments),
    regions: Object.entries(FF.getValue(product.regions))
      .filter(([, region]) => region)
      .map(([k]) => k),
  })),
  filter(compose(propEq("checked", true), nth(1))),
  Object.entries,
  FF.getValue
);

export const hasUserInteraction = createSelector(
  [getContactInformation, getProductFields],
  (contactInformation, productFields) => {
    const checkedProducts = normalizeCheckedProducts(productFields);

    const isAnyShipmentFieldFilled = checkedProducts.filter((product) => {
      return product.numberOfShipments || product.frequency
        ? product
        : undefined;
    });

    if (isAnyShipmentFieldFilled.length > 0) {
      return isAnyShipmentFieldFilled.length > 0;
    }

    const isAnyContactFormFieldFilled = FILLABLE_CONTACT_FORM_FIELDS.filter(
      (field) => {
        if (RD.isRemoteData(contactInformation[field])) {
          return compose(
            RD.withDefault(""),
            RD.map(FF.getValue)
          )(contactInformation[field]);
        }
        return FF.getValue(contactInformation[field]) ? field : undefined;
      }
    );
    return isAnyContactFormFieldFilled.length > 0;
  }
);

const getParcelBusinessUnitsOptions = createSelector(
  getConfiguration,
  compose(
    RD.withDefault({}),
    RD.map(
      compose(
        reduce(
          (allBusinessUnits, region) =>
            region.businessUnits.reduce(
              // We are shadowing `allBusinessUnits` variable on purpose to show that it is the same variable passing through the reducers
              // eslint-disable-next-line no-shadow
              (allBusinessUnits, businessUnit) => [
                ...allBusinessUnits,
                {
                  ...businessUnit,
                  region: region.shipmentScaleId,
                  threshold: businessUnit.threshold || [
                    {
                      numberOfShipments: 0,
                      timeInterval: "MONTHLY",
                    },
                    {
                      numberOfShipments: 0,
                      timeInterval: "WEEKLY",
                    },
                    {
                      numberOfShipments: 0,
                      timeInterval: "DAILY",
                    },
                  ],
                },
              ],
              allBusinessUnits
            ),
          []
        ),
        propOr([], "shipmentScales"),
        find(propEq("productId", "PARCEL")),
        prop("settings")
      )
    )
  )
);

const getFreightBusinessUnitsOptions = createSelector(
  getConfiguration,
  compose(
    RD.withDefault({}),
    RD.map(
      compose(
        reduce(
          (allBusinessUnits, region) =>
            region.businessUnits.reduce(
              // We are shadowing `allBusinessUnits` variable on purpose to show that it is the same variable passing through the reducers
              // eslint-disable-next-line no-shadow
              (allBusinessUnits, businessUnit) => [
                ...allBusinessUnits,
                {
                  ...businessUnit,
                  region: region.shipmentScaleId,
                  threshold: businessUnit.threshold || [
                    {
                      numberOfShipments: 0,
                      timeInterval: "MONTHLY",
                    },
                    {
                      numberOfShipments: 0,
                      timeInterval: "WEEKLY",
                    },
                    {
                      numberOfShipments: 0,
                      timeInterval: "DAILY",
                    },
                  ],
                },
              ],
              allBusinessUnits
            ),
          []
        ),
        propOr([], "shipmentScales"),
        find(propEq("productId", "FREIGHT")),
        prop("settings")
      )
    )
  )
);

const getMailBusinessUnitsOptions = createSelector(
  getConfiguration,
  compose(
    RD.withDefault({}),
    RD.map(
      compose(
        reduce(
          (allBusinessUnits, region) =>
            region.businessUnits.reduce(
              // We are shadowing `allBusinessUnits` variable on purpose to show that it is the same variable passing through the reducers
              // eslint-disable-next-line no-shadow
              (allBusinessUnits, businessUnit) => [
                ...allBusinessUnits,
                {
                  ...businessUnit,
                  region: region.shipmentScaleId,
                  threshold: businessUnit.threshold || [
                    {
                      numberOfShipments: 0,
                      timeInterval: "MONTHLY",
                    },
                    {
                      numberOfShipments: 0,
                      timeInterval: "WEEKLY",
                    },
                    {
                      numberOfShipments: 0,
                      timeInterval: "DAILY",
                    },
                  ],
                },
              ],
              allBusinessUnits
            ),
          []
        ),
        propOr([], "shipmentScales"),
        find(propEq("productId", "MAIL")),
        prop("settings")
      )
    )
  )
);

export const getNoMatches = createSelector(
  getProductFields,
  getConfiguration,
  (fields, remoteConfiguration) =>
    RD.case(
      {
        success: ({ settings }) => {
          const normalizedProducts = normalizeCheckedProducts(fields);

          return normalizedProducts.reduce((allNoMatches, product) => {
            const noMatch = {
              frequency: product.frequency,
              numberOfShipments: product.numberOfShipments,
              options: product.options,
              productId: product.id,
              regions: product.regions,
            };

            const settingsProduct = settings.find(
              propEq("productId", product.id)
            );

            if (!settingsProduct) {
              return [...allNoMatches, noMatch];
            }

            const settingsRegions = settingsProduct.shipmentScales.filter(
              ({ shipmentScaleId }) => product.regions.includes(shipmentScaleId)
            );

            if (!settingsRegions) {
              return [...allNoMatches, noMatch];
            }

            const businessUnits = settingsRegions
              .flatMap(prop("businessUnits"))
              .map((bu) => ({
                ...bu,
                threshold: bu.threshold || [
                  {
                    numberOfShipments: 0,
                    timeInterval: "MONTHLY",
                  },
                  {
                    numberOfShipments: 0,
                    timeInterval: "WEEKLY",
                  },
                  {
                    numberOfShipments: 0,
                    timeInterval: "DAILY",
                  },
                ],
              }));

            let unmatchThresold;

            const hasNoBusinessUnitsThatMatches = !businessUnits.some((bu) => {
              if (
                bu.threshold.some(
                  (t) =>
                    product.numberOfShipments < t.numberOfShipments &&
                    product.frequency === t.timeInterval
                )
              ) {
                unmatchThresold = bu.threshold;
                return false;
              }

              // Only check if the options are set for form and BU
              if (product.options && bu.options) {
                // Only check if not both BU options are true, when both are true no need for further filtering
                if (!(bu.options.expedited && bu.options.standard)) {
                  // Only check if any are set from the user, when both are unchecked we don't filter
                  if (
                    product.options.value.expedited ||
                    product.options.value.standard
                  ) {
                    // When BU demands one option but it's not checked
                    if (
                      (!product.options.value.expedited &&
                        bu.options.expedited) ||
                      (!product.options.value.standard && bu.options.standard)
                    ) {
                      return false;
                    }
                  }
                }
              }

              return true;
            });

            if (hasNoBusinessUnitsThatMatches) {
              return [
                ...allNoMatches,
                {
                  ...noMatch,
                  threshold: unmatchThresold || undefined,
                },
              ];
            }

            return allNoMatches;
          }, []);
        },
        _: () => [],
      },
      remoteConfiguration
    )
);

const getParcelOption = (options) => {
  if (options?.standard && !options?.expedited) {
    return PARCEL_OPTIONS.STANDARD;
  }
  if (!options?.standard && options?.expedited) {
    return PARCEL_OPTIONS.EXPEDITED;
  }
  return undefined;
};

export const getParcelNoMatches = createSelector(
  getProductFields,
  getParcelBusinessUnitsOptions,
  (fields, businessUnitsOptions) => {
    const values = FF.getValue(fields);
    const { PARCEL: parcel } = values;

    if (
      !parcel ||
      [parcel.numberOfShipments, parcel.frequency, parcel.regions].some(
        complement(FF.isValid)
      )
    ) {
      return undefined;
    }

    const numberOfShipments = FF.getValue(parcel.numberOfShipments);
    const frequency = FF.getValue(parcel.frequency);
    const checkedRegions = Object.entries(FF.getValue(parcel.regions))
      .filter(nth(1))
      .map(nth(0));
    const checkedOption = getParcelOption(FF.getValue(parcel.options));

    let uniqueBUs;
    if (checkedOption) {
      uniqueBUs = uniq(
        businessUnitsOptions
          .filter((bu) => checkedRegions.includes(bu.region))
          .filter(
            (bu) =>
              !("options" in bu) ||
              ("options" in bu && checkedOption in bu?.options)
          )
          .map(prop("businessUnitId"))
      );
    } else {
      uniqueBUs = uniq(
        businessUnitsOptions
          .filter((bu) => checkedRegions.includes(bu.region))
          .map(prop("businessUnitId"))
      );
    }

    for (let i = 0; i < checkedRegions.length; i += 1) {
      const region = checkedRegions[i];
      const noMatch = businessUnitsOptions.find(
        (bu) =>
          bu.region === region &&
          bu.threshold.some(
            (t) =>
              numberOfShipments < t.numberOfShipments &&
              t.timeInterval === frequency
          )
      );

      if (
        uniqueBUs.length === 1 &&
        noMatch &&
        noMatch?.businessUnitId === uniqueBUs[0]
      ) {
        return noMatch;
      }
    }

    return undefined;
  }
);

export const getFreightNoMatches = createSelector(
  getProductFields,
  getFreightBusinessUnitsOptions,
  (fields, businessUnitsOptions) => {
    const values = FF.getValue(fields);
    const { FREIGHT: freight } = values;

    if (
      !freight ||
      [freight.numberOfShipments, freight.frequency, freight.regions].some(
        complement(FF.isValid)
      )
    ) {
      return undefined;
    }

    const numberOfShipments = FF.getValue(freight.numberOfShipments);
    const frequency = FF.getValue(freight.frequency);
    const checkedRegions = Object.entries(FF.getValue(freight.regions))
      .filter(nth(1))
      .map(nth(0));
    const uniqueBUs = uniq(
      businessUnitsOptions
        .filter((bu) => checkedRegions.includes(bu.region))
        .map(prop("businessUnitId"))
    );

    for (let i = 0; i < checkedRegions.length; i += 1) {
      const region = checkedRegions[i];
      const noMatch = businessUnitsOptions.find(
        (bu) =>
          bu.region === region &&
          bu.threshold.some(
            (t) =>
              numberOfShipments < t.numberOfShipments &&
              t.timeInterval === frequency
          )
      );

      if (uniqueBUs.length === 1 && noMatch) {
        return noMatch;
      }
    }

    return undefined;
  }
);

export const getMailNoMatches = createSelector(
  getProductFields,
  getMailBusinessUnitsOptions,
  (fields, businessUnitsOptions) => {
    const values = FF.getValue(fields);
    const { MAIL: mail } = values;

    if (
      !mail ||
      [mail.numberOfShipments, mail.frequency, mail.regions].some(
        complement(FF.isValid)
      )
    ) {
      return undefined;
    }

    const numberOfShipments = FF.getValue(mail.numberOfShipments);
    const frequency = FF.getValue(mail.frequency);
    const checkedRegions = Object.entries(FF.getValue(mail.regions))
      .filter(nth(1))
      .map(nth(0));
    const uniqueBUs = uniq(
      businessUnitsOptions
        .filter((bu) => checkedRegions.includes(bu.region))
        .map(prop("businessUnitId"))
    );

    for (let i = 0; i < checkedRegions.length; i += 1) {
      const region = checkedRegions[i];
      const noMatch = businessUnitsOptions.find(
        (bu) =>
          bu.region === region &&
          bu.threshold.some(
            (t) =>
              numberOfShipments < t.numberOfShipments &&
              t.timeInterval === frequency
          )
      );

      if (uniqueBUs.length === 1 && noMatch) {
        return noMatch;
      }
    }

    return undefined;
  }
);

export const getShouldShowOptions = createSelector(
  getProductFields,
  getParcelBusinessUnitsOptions,
  (fields, businessUnitsOptions) => {
    const values = FF.getValue(fields);
    // If values are not set or PARCEL is not set at all, dismiss
    if (!values || !values.PARCEL || !values.PARCEL.options) {
      return false;
    }

    const { PARCEL: parcel } = values;

    // If any of the product detail inputs of PARCEL is not yet valid, dismiss
    if (
      [parcel.numberOfShipments, parcel.frequency, parcel.regions].some(
        complement(FF.isValid)
      )
    ) {
      return false;
    }

    const numberOfShipments = FF.getValue(parcel.numberOfShipments);
    const frequency = FF.getValue(parcel.frequency);
    const checkedRegions = Object.entries(FF.getValue(parcel.regions))
      .filter(nth(1))
      .map(nth(0));

    // Loop through the selected regions and check if the BUs in there contain urgency options
    // and are matched to the current user input
    const regionsWithOptions = [];
    for (let i = 0; i < checkedRegions.length; i += 1) {
      const region = checkedRegions[i];

      const filteredBUs = businessUnitsOptions
        .filter(
          (bu) =>
            bu.region === region &&
            bu.threshold.some(
              (t) =>
                numberOfShipments >= t.numberOfShipments &&
                t.timeInterval === frequency
            )
        )
        .map((bu) => ({ ...bu, options: bu.options ?? {} }));
      if (
        filteredBUs.some((bu) => bu.options.expedited) ||
        filteredBUs.some((bu) => bu.options.standard)
      ) {
        regionsWithOptions.push(region);
      }
    }

    // Return true if any of the selected regions contain BUs with urgency options
    return regionsWithOptions.length > 0;
  }
);

export const getMatchedBUs = createSelector(
  getProductFields,
  getConfiguration,
  getShouldShowOptions,
  getLanguage,
  (fields, remoteConfiguration, shouldShowOptions, language) =>
    RD.case(
      {
        success: ({ settings }) => {
          const normalizedProducts = normalizeCheckedProducts(fields);

          return normalizedProducts.reduce((allBusinessUnits, product) => {
            const settingsProduct = settings.find(
              propEq("productId", product.id)
            );

            if (!settingsProduct) {
              return allBusinessUnits;
            }

            const settingsRegions = settingsProduct.shipmentScales.filter(
              ({ shipmentScaleId }) => product.regions.includes(shipmentScaleId)
            );

            if (!settingsRegions) {
              return allBusinessUnits;
            }

            return settingsRegions
              .flatMap((s) =>
                s.businessUnits.map((b) => ({
                  ...b,
                  shipmentScaleId: s.shipmentScaleId,
                }))
              )
              .reduce((allBUs, { shipmentScaleId, businessUnitId, ...bu }) => {
                const matchId = [product.id, product.frequency].join("-");
                const match = {
                  id: matchId,
                  frequency: product.frequency,
                  numberOfShipments: product.numberOfShipments,
                  options: product.options,
                  productId: product.id,
                  regions: product.regions,
                };

                const combination = {
                  product: product.id,
                  region: shipmentScaleId,
                };

                const selfOnBoardingUrl = compose(prop("url"))(
                  bu.selfOnBoardingUrls.find(
                    (el) => el.language === language
                  ) ||
                    bu.selfOnBoardingUrls.find(
                      (el) => el.language === "default"
                    )
                );

                const options =
                  shouldShowOptions && bu.options
                    ? Object.entries(bu.options)
                        .filter(nth(1))
                        .map(([key]) =>
                          key === "standard" ? "Standard" : "Expedited"
                        )
                    : undefined;
                if (
                  bu.threshold &&
                  bu.threshold.some(
                    (t) =>
                      product.numberOfShipments < t.numberOfShipments &&
                      product.frequency === t.timeInterval
                  )
                ) {
                  return allBUs;
                }

                // Only check if the options are set for form and BU
                if (product.options && bu.options) {
                  // Only check if not both BU options are true, when both are true no need for further filtering
                  if (!(bu.options.expedited && bu.options.standard)) {
                    // Only check if any are set from the user, when both are unchecked we don't filter
                    if (
                      product.options.value.expedited ||
                      product.options.value.standard
                    ) {
                      // When BU demands expedited but it's not checked return
                      if (
                        !product.options.value.expedited &&
                        bu.options.expedited
                      ) {
                        return allBUs;
                      }

                      // When BU demands standard but it's not checked return
                      if (
                        !product.options.value.standard &&
                        bu.options.standard
                      ) {
                        return allBUs;
                      }
                    }
                  }
                }

                const buInList = allBUs.find(propEq("id", businessUnitId));

                if (buInList) {
                  return [
                    ...allBUs.filter(complement(propEq("id", businessUnitId))),
                    {
                      ...buInList,
                      parameters: {
                        ...buInList.parameters,
                        options: options
                          ? uniq([
                              ...(buInList.parameters.options || []),
                              ...options,
                            ])
                          : buInList.parameters.options,
                        combinations: [
                          ...buInList.parameters.combinations,
                          combination,
                        ],
                      },
                      matches: [
                        ...buInList.matches.filter(({ id }) => id !== matchId),
                        match,
                      ],
                    },
                  ];
                }

                return [
                  ...allBUs,
                  {
                    ...bu,
                    id: businessUnitId,
                    parameters: {
                      combinations: [combination],
                      options,
                    },
                    matches: [match],
                    selfOnBoardingUrl,
                  },
                ];
              }, allBusinessUnits);
          }, []);
        },
        _: () => [],
      },
      remoteConfiguration
    )
);

export const getShouldShowSelfOnboarding = createSelector(
  getMatchedBUs,
  (matchedBUs) =>
    matchedBUs.every((el) => el.selfOnBoardingUrl && !el.salesLeadQualified) ||
    Boolean(matchedBUs.length === 1 && matchedBUs[0].selfOnBoardingUrl)
);

export const getConnectors = createSelector(
  compose(
    RD.withDefault(undefined),
    RD.map(prop("showConnectors")),
    getFeatureFlags
  ),
  getMatchedBUs,
  getProfile,
  (connectors, businessUnits, profile) => {
    if (
      connectors === undefined ||
      (isFSLikeProfile(profile) && businessUnits.length !== 1)
    ) {
      return undefined;
    }

    let connectorsBU;
    if (isFSLikeProfile(profile)) {
      connectorsBU = businessUnits[0].id;
    } else {
      connectorsBU = PROFILE_SPECIFIC_BUS[profile];
    }

    const [id, value] =
      Object.entries(connectors).find(([bu]) => bu === connectorsBU) || [];

    return value && { ...value, id };
  }
);

export const getShouldShowConnectorsStep = (state) => {
  const connectors = getConnectors(state);

  return (
    connectors !== undefined &&
    (Boolean(connectors.callIn) ||
      Boolean(connectors.contactForm) ||
      Boolean(connectors.liveChat) ||
      Boolean(connectors.selfRegister) ||
      Boolean(connectors.openAnAccount))
  );
};

export const getIsCallbackSingleConnector = (state) => {
  const connectors = getConnectors(state);
  /* decreasing by one here because we have to consider the id param being present as well */
  const connectorsOptionsLength =
    connectors !== undefined ? Object.entries(connectors).length - 1 : 0;

  return (
    connectors !== undefined &&
    Boolean(connectors.callBack) &&
    connectorsOptionsLength === 1
  );
};

export const getConnectorsBU = (state) => {
  const connectors = getConnectors(state);

  return connectors?.id;
};

export const getCallbackRemoteSlots = createSelector(
  [getConnectorsBU, getCallbackRemote],
  (connectorsBU, callbackRemote) =>
    compose(
      propOr(RD.notAsked(), connectorsBU),
      prop("options")
    )(callbackRemote)
);

export const getCallbackRemoteSlotsError = createSelector(
  [getConnectorsBU, getCallbackRemote],
  (connectorsBU, callbackRemote) =>
    compose(
      propOr(null, "error"),
      propOr(null, connectorsBU),
      prop("options")
    )(callbackRemote)
);

export const getIsScheduledLead = (state) => {
  const connectors = getConnectors(state);
  const scheduler = lead.getScheduler(state.lead);

  return connectors?.callBack && scheduler.schedulerDisplay;
};

export const getContactFormType = (state) => {
  if (!getConnectors(state)) {
    return PAYLOAD_CONTACT_FORM_TYPES.DEFAULT;
  }

  if (getCallbackRemoteDisplay(state)) {
    return PAYLOAD_CONTACT_FORM_TYPES.CALLBACK_REMOTE;
  }

  return getSchedulerDisplay(state)
    ? PAYLOAD_CONTACT_FORM_TYPES.CALLBACK
    : PAYLOAD_CONTACT_FORM_TYPES.VANILLA;
};

export const getConnectorsTimeZone = createSelector(
  [getFeatureFlags, getConnectorsBU],
  (featureFlags, connectorsBU) =>
    compose(
      (timeZone) => getTimeZoneFromConfig(timeZone, connectorsBU),
      prop("timeZone"),
      RD.withDefault(undefined),
      RD.map(prop("showConnectors"))
    )(featureFlags)
);

export const getConnectorsWeekendDays = createSelector(
  getFeatureFlags,
  compose(
    propOr(CONNECTORS_DEFAULT_WEEKEND_DAYS, "weekendDays"),
    RD.withDefault(undefined),
    RD.map(prop("showConnectors"))
  )
);

export const isNextStepEnabled = createSelector(
  getActiveStep,
  getProductFields,
  getContactInformation,
  getShouldShowOptions,
  getMatchedBUs,
  (
    activeStep,
    productFields,
    contactInformation,
    shouldShowOptions,
    matchedBUs
  ) => {
    switch (activeStep) {
      case STEPS.INTRO:
      case STEPS.SERVICES:
        return true;

      case STEPS.PRODUCT: {
        return productFields ? FF.isValid(productFields) : false;
      }

      case STEPS.PRODUCT_DETAILS: {
        return productFields
          ? FF.isValid(productFields) &&
              FF.case(
                {
                  valid: (products) =>
                    !Object.values(products).some((p) => {
                      return (
                        p.checked &&
                        (!FF.isValid(p.numberOfShipments) ||
                          !FF.isValid(p.regions) ||
                          !FF.isValid(p.frequency) ||
                          (p.options &&
                            shouldShowOptions &&
                            !FF.isValid(p.options)))
                      );
                    }),
                  _: always(false),
                },
                productFields
              ) &&
              Boolean(matchedBUs.length)
          : false;
      }

      case STEPS.CONNECTORS:
      case STEPS.CONTACT_FORM: {
        const contactInformationFields = Object.values(contactInformation);
        for (let i = 0; i < contactInformationFields.length; i += 1) {
          const field = contactInformationFields[i];

          if (FF.isFormField(field) && !FF.isValid(field)) {
            return false;
          }

          if (
            RD.isRemoteData(field) &&
            RD.withDefault(true, RD.map(complement(FF.isValid), field))
          ) {
            return false;
          }
        }

        return true;
      }

      default:
        return false;
    }
  }
);

export const getSingleProduct = createSelector(getProductFields, (fields) => {
  const products = compose(Object.keys, FF.getValue)(fields);

  return products.length === 1 ? products[0] : undefined;
});

export const getSingleProductRerouting = createSelector(
  getSingleProduct,
  compose(
    RD.withDefault(undefined),
    RD.map(prop("showRerouting")),
    getFeatureFlags
  ),
  (singleProduct, reroutingData) =>
    reroutingData?.singleProduct?.[singleProduct]
);

export const getSingleRegion = createSelector(getProductFields, (fields) => {
  const regions = compose(
    uniq,
    flatten,
    map(compose(Object.keys, FF.getValue, prop("regions"))),
    Object.values,
    FF.getValue
  )(fields);

  return regions.length === 1 ? regions[0] : undefined;
});

export const getSingleRegionRerouting = createSelector(
  getSingleRegion,
  compose(
    RD.withDefault(undefined),
    RD.map(prop("showRerouting")),
    getFeatureFlags
  ),
  (singleRegion, reroutingData) => reroutingData?.singleRegion?.[singleRegion]
);

export const getReroutingRegionProductDetails = compose(
  prop("regionProductDetails"),
  RD.withDefault(undefined),
  RD.map(prop("showRerouting")),
  getFeatureFlags
);

export const getReroutingThresholdProductDetails = compose(
  prop("thresholdProductDetails"),
  RD.withDefault(undefined),
  RD.map(prop("showRerouting")),
  getFeatureFlags
);

export const getMedalliaSurveyEnabled = compose(
  RD.withDefault(undefined),
  RD.map(prop("medalliaSurveyEnabled")),
  getFeatureFlags
);

export const getHideThankYouTeaser = compose(
  RD.withDefault(undefined),
  RD.map(prop("hideThankYouTeaser")),
  getFeatureFlags
);

export const getOpenLinksInSameTab = compose(
  RD.withDefault(undefined),
  RD.map(prop("openLinksInSameTab")),
  getFeatureFlags
);

export const getEnhancedBUCard = compose(
  RD.withDefault(null),
  RD.map(prop("enhancedBUCard")),
  getFeatureFlags
);

export const getScrollOffset = compose(
  RD.withDefault(null),
  RD.map(prop("scrollOffset")),
  getFeatureFlags
);

export const getLeftAlignContent = compose(
  RD.withDefault(false),
  RD.map(prop("leftAlignContent")),
  getFeatureFlags
);

export const getShouldHideProceedStep = createSelector(
  getActiveStep,
  getShouldShowSelfOnboarding,
  (activeStep, shouldShowSelfOnboarding) => {
    const isSelfOnboarding =
      activeStep === STEPS.SERVICES && shouldShowSelfOnboarding;

    const isLastStep = [STEPS.CONNECTORS, STEPS.THANK_YOU].includes(activeStep);

    return isSelfOnboarding || isLastStep;
  }
);

export const getIsHighlightConnectorEnabled = (state) => {
  const connectors = getConnectors(state);
  if (!connectors) {
    return false;
  }
  const result = Object.keys(connectors).findLast(
    (key) => connectors[key]?.isHighlighted === true
  );
  return result !== undefined;
};

export default reducer;
