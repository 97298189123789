import React, { memo } from "react";
import styled, { css } from "styled-components";
import { DhlButton } from "@dhl-official/react-library";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { STYLES_VIEWPORTS, STEPS, PROFILES } from "../../../utils/constants";
import * as reducers from "../../../reducers";

const NEXT_BUTTON_CONTENT_IDS = {
  [PROFILES.FS]: {
    [STEPS.PRODUCT]: "buttonProceed",
    [STEPS.PRODUCT_DETAILS]: "buttonProceed",
    [STEPS.SERVICES]: "buttonContinueToContact",
    [STEPS.CONTACT_FORM]: "buttonSend",
  },
  [PROFILES.FSDEC]: {
    [STEPS.PRODUCT]: "buttonProceed",
    [STEPS.PRODUCT_DETAILS]: "buttonProceed",
    [STEPS.SERVICES]: "buttonContinueToContact",
    [STEPS.CONTACT_FORM]: "buttonSend",
  },
  [PROFILES.FSX]: {
    [STEPS.INTRO]: "buttonProceedFromIntro",
    [STEPS.PRODUCT_DETAILS]: "buttonContinueToContact",
    [STEPS.CONTACT_FORM]: "buttonSend",
  },
};

const getStyleForDisabledButton = ($visuallyDisabled) => {
  return (
    $visuallyDisabled &&
    css`
      & > .styled.primary:is(button) {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: unset;
        color: var(--dui-color-white-500);
      }
    `
  );
};

const NextButtonStyled = styled(DhlButton)`
  & button {
    cursor: pointer;
    margin: var(--dui-size-space-3x);
    min-width: 70%;
    width: auto;
    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      min-width: 210px;
    }
  }

  ${({ $visuallyDisabled }) => getStyleForDisabledButton($visuallyDisabled)};
`;

export const getNextButtonContentId = (activeStep, contentPrefix, profile) => {
  return `${contentPrefix}.MultiPageFormStep.${
    NEXT_BUTTON_CONTENT_IDS?.[profile]?.[activeStep] || "buttonProceed"
  }`;
};

const NextButton = ({
  isNextStepEnabled,
  isWaitingForEndpoint,
  dataTestid,
  dataTracking,
  name,
  onClick,
  activeStep,
  contentPrefix,
  profile,
  children,
}) => {
  return (
    <NextButtonStyled
      $visuallyDisabled={!isNextStepEnabled}
      isDisabled={isWaitingForEndpoint ? true : null}
      dataAriaDisabled={!isNextStepEnabled}
      dataTestid={dataTestid}
      dataTracking={dataTracking}
      name={name}
      type="submit"
      clickEvent={onClick}
    >
      {children || (
        <div>
          <FormattedMessage
            id={getNextButtonContentId(activeStep, contentPrefix, profile)}
          />
        </div>
      )}
    </NextButtonStyled>
  );
};

NextButton.propTypes = {
  // input
  onClick: PropTypes.func.isRequired,
  dataTestid: PropTypes.string.isRequired,
  dataTracking: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // from redux
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  contentPrefix: PropTypes.string.isRequired,
  isNextStepEnabled: PropTypes.bool,
  isWaitingForEndpoint: PropTypes.bool,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  activeStep: reducers.getActiveStep(state),
  contentPrefix: reducers.getContentPrefix(state),
  profile: reducers.getProfile(state),
  isNextStepEnabled: reducers.isNextStepEnabled(state),
  isWaitingForEndpoint: reducers.getIsLoadingGotLead(state),
});

export default memo(connect(mapStateToProps, {})(NextButton));
