import { combineReducers } from "redux";
import businessUnitsReducer, * as businessUnits from "./business-units";
import callbackRemoteReducer, * as callbackRemote from "./callback-remote";
import contactInformationReducer, * as contactInformation from "./contact-information";
import schedulerReducer, * as scheduler from "./scheduler";
import shipmentProductReducer, * as shipmentProduct from "./shipment-product";

export default combineReducers({
  businessUnits: businessUnitsReducer,
  callbackRemote: callbackRemoteReducer,
  contactInformation: contactInformationReducer,
  scheduler: schedulerReducer,
  shipmentProduct: shipmentProductReducer,
});

export const getUniqueId = (state) =>
  businessUnits.getUniqueId(state.businessUnits);

export const getIsLoadingGotLead = (state) =>
  businessUnits.getIsLoadingGotLead(state.businessUnits);

export const getBusThatGotLead = (state) =>
  businessUnits.getBusThatGotLead(state.businessUnits);

export const getContactInformation = (state) =>
  contactInformation.get(state.contactInformation);

export const getProductOptions = (state) =>
  shipmentProduct.getOptions(state.shipmentProduct);

export const getProductFields = (state) =>
  shipmentProduct.getFields(state.shipmentProduct);

export const getScheduler = (state) => scheduler.get(state.scheduler);

export const getCallbackRemote = (state) =>
  callbackRemote.get(state.callbackRemote);
