import { always, compose, prop, flip, filter, pluck, sort } from "ramda";
import RD from "../../../utils/functional/remote-data";
import FF, {
  Meta as M,
  Validation as V,
} from "../../../utils/functional/form-field";
import {
  LOAD_CONFIGURATION_SUCCESS,
  LOAD_CONFIGURATION,
  SET_PRODUCT_FREQUENCY,
  SET_PRODUCT_NUMBER,
  SET_PRODUCT_URGENCY,
  SET_SHIPMENT_PRODUCT,
  SET_PRODUCT_REGION,
  SET_VALIDATED_SHIPMENT_PRODUCT,
  SET_VALIDATED_PRODUCT_DETAILS,
  VALIDATE_PRODUCT_NUMBER,
  REMOVE_SHIPMENT_PRODUCT,
} from "../../../actions";
import {
  FREQUENCY_OPTIONS,
  PARCEL_OPTIONS,
  PRESELECTED_PRODUCTS,
  PRODUCTS_ORDER,
  REGIONS_ORDER,
} from "../../../utils/constants";
import { isFSLikeProfile } from "../../../utils";

const INITIAL_STATE = RD.notAsked();

const COPY_ID_PREFIX = "FS2.Validations";

const NUMBER_MISSING = `${COPY_ID_PREFIX}.numberOfShipmentsvalueMissing`;
const NUMBER_OVERFLOW = `${COPY_ID_PREFIX}.numberOfShipmentsrangeOverflow`;
const NUMBER_UNDERFLOW = `${COPY_ID_PREFIX}.numberOfShipmentsrangeUnderflow`;
const PATTERN_MISMATCH = `${COPY_ID_PREFIX}.numberOfShipmentspatternMismatch`;
const PRODUCT_MISSING = `${COPY_ID_PREFIX}.shipmentProductvalueMissing`;
const FREQUENCY_MISSING = `${COPY_ID_PREFIX}.shipmentFrequencyValueMissing`;
const FREQUENCY_INVALID = `${COPY_ID_PREFIX}.shipmentFrequencyNotAllowed`;

const isEmptyValidatedField = (formField) =>
  FF.isValid(formField) && FF.getValue(formField) === "";

const makeFieldValid = FF.chain(FF.valid);

const setNumberOfShipmentsField = (id) =>
  FF.pristine(
    M.create(
      [
        V.required(NUMBER_MISSING),
        V.pattern(PATTERN_MISMATCH, "^\\d+$"),
        V.numberRange(0, 1000000000, NUMBER_UNDERFLOW, NUMBER_OVERFLOW),
      ],
      `shipmentProduct_${id}_numberOfShipments`
    ),
    ""
  );

const createRegionFields = (regions) => {
  const formField = regions.length === 1 ? FF.valid : FF.pristine;

  return formField(
    M.create(
      [V.groupRequired("FS2.Validations.shipmentScalevalueMissing")],
      "shipmentScale"
    ),
    regions.reduce(
      (allRegions, option) => ({
        ...allRegions,
        [option]: regions.length === 1,
      }),
      {}
    )
  );
};

const createFieldOptionFields = (options) => {
  return FF.pristine(
    M.create(
      [V.groupRequired("FS2.Validations.shipmentScalevalueMissing")],
      "fieldOptions"
    ),
    options.reduce(
      (allOptions, option) => ({
        ...allOptions,
        [option]: options.length === 1,
      }),
      {}
    )
  );
};

const getRegions = compose(
  sort((a, b) => REGIONS_ORDER.indexOf(a) - REGIONS_ORDER.indexOf(b)),
  pluck("shipmentScaleId"),
  filter((s) => (s.businessUnits || []).length)
);

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_CONFIGURATION:
      return RD.loading();

    case LOAD_CONFIGURATION_SUCCESS: {
      const { configuration, settings, profile } = payload;

      const shouldPresetProductFields = !!(
        !isFSLikeProfile(profile) &&
        configuration?.featureToggles?.showProductDetailsPage &&
        PRESELECTED_PRODUCTS?.[profile]
      );

      const shipmentProductFieldState = shouldPresetProductFields
        ? FF.valid
        : FF.pristine;

      const createShipmentProductField = shipmentProductFieldState(
        M.create(
          [V.groupRequired(PRODUCT_MISSING, "checked")],
          "shipmentProduct"
        )
      );

      const options = compose(
        sort((a, b) => PRODUCTS_ORDER.indexOf(a) - PRODUCTS_ORDER.indexOf(b)),
        pluck("productId"),
        filter((s) => s.shipmentScales.length)
      )(settings);

      const checkProductPreselect = (id) =>
        shouldPresetProductFields && PRESELECTED_PRODUCTS[profile].includes(id);

      const fields = settings.reduce((allProducts, config) => {
        if (config.shipmentScales.length === 0) {
          return allProducts;
        }

        const id = config.productId;
        const regions = getRegions(config.shipmentScales);
        const shouldShowOptions = config.shipmentScales.some((s) =>
          s.businessUnits.some((bu) => Boolean(bu.options))
        );

        return {
          ...allProducts,
          [id]: {
            checked: checkProductPreselect(id),
            frequency: FF.pristine(
              M.create(
                [
                  V.required(FREQUENCY_MISSING),
                  V.inDataset(
                    FREQUENCY_INVALID,
                    FREQUENCY_OPTIONS.map(prop("value"))
                  ),
                ],
                `shipmentProduct_${id}_frequency`
              ),
              undefined
            ),
            numberOfShipments: setNumberOfShipmentsField(id),
            options: shouldShowOptions
              ? createFieldOptionFields([
                  PARCEL_OPTIONS.STANDARD,
                  PARCEL_OPTIONS.EXPEDITED,
                ])
              : undefined,
            regions: createRegionFields(regions),
          },
        };
      }, {});

      return RD.success({
        fields: createShipmentProductField(fields),
        options,
      });
    }

    case SET_PRODUCT_REGION: {
      const [id, region] = payload;

      return RD.map(
        (product) => ({
          ...product,
          fields: FF.map((allFields) => {
            const { regions } = allFields[id];

            return {
              ...allFields,
              [id]: {
                ...allFields[id],
                checked: true,
                regions: compose(
                  FF.validate,
                  FF.map((allRegions) => ({
                    ...allRegions,
                    [region]: !allRegions[region],
                  }))
                )(regions),
              },
            };
          })(product.fields),
        }),
        state
      );
    }

    case SET_SHIPMENT_PRODUCT: {
      const [id, checked] = payload;

      const clearField = FF.chain(flip(FF.unchecked)(""));

      return RD.map(
        (product) => ({
          ...product,
          fields: compose(
            FF.validate,
            FF.map((allFields) => {
              const { numberOfShipments } = allFields[id];

              return {
                ...allFields,
                [id]: {
                  ...allFields[id],
                  numberOfShipments:
                    (FF.isInvalid(numberOfShipments) && !checked) ||
                    isEmptyValidatedField(numberOfShipments)
                      ? clearField(numberOfShipments)
                      : numberOfShipments,
                  checked,
                },
              };
            })
          )(product.fields),
        }),
        state
      );
    }

    case REMOVE_SHIPMENT_PRODUCT:
      return RD.map(
        (product) => ({
          ...product,
          fields: compose(
            FF.validate,
            FF.map((allFields) => ({
              ...allFields,
              [payload.id]: {
                ...allFields[payload.id],
                checked: false,
              },
            }))
          )(product.fields),
        }),
        state
      );

    case SET_PRODUCT_FREQUENCY: {
      const [id, value] = payload;

      return RD.map(
        (product) => ({
          ...product,
          fields: compose(
            makeFieldValid,
            FF.map((allFields) => ({
              ...allFields,
              [id]: {
                ...allFields[id],
                checked: true,
                frequency: compose(
                  FF.validate,
                  FF.map(always(value))
                )(allFields[id].frequency),
              },
            }))
          )(product.fields),
        }),
        state
      );
    }

    case SET_PRODUCT_NUMBER: {
      const [id, value] = payload;

      return RD.map(
        (product) => ({
          ...product,
          fields: compose(
            FF.validate,
            FF.map((allFields) => {
              const { numberOfShipments } = allFields[id];

              return {
                ...allFields,
                [id]: {
                  ...allFields[id],
                  checked: true,
                  numberOfShipments: compose(
                    FF.validate,
                    FF.map(always(value))
                  )(numberOfShipments),
                },
              };
            })
          )(product.fields),
        }),
        state
      );
    }

    case VALIDATE_PRODUCT_NUMBER: {
      return RD.map(
        (product) => ({
          ...product,
          fields: FF.map((allFields) =>
            Object.keys(allFields).reduce((allProducts, key) => {
              const field = allFields[key];

              return {
                ...allProducts,
                [key]: {
                  ...field,
                  numberOfShipments: field.checked
                    ? FF.validate(field.numberOfShipments)
                    : makeFieldValid(field.numberOfShipments),
                },
              };
            }, {})
          )(product.fields),
        }),
        state
      );
    }

    case SET_PRODUCT_URGENCY: {
      return RD.map(
        (product) => ({
          ...product,
          fields: compose(
            FF.validate,
            FF.map((allFields) => ({
              ...allFields,
              PARCEL: {
                ...allFields.PARCEL,
                checked: true,
                options: compose(
                  FF.validate,
                  FF.map((allOptions) => {
                    return {
                      ...allOptions,
                      [payload]: !allFields.PARCEL.options.value[payload],
                    };
                  })
                )(allFields.PARCEL.options),
              },
            }))
          )(product.fields),
        }),
        state
      );
    }

    case SET_VALIDATED_SHIPMENT_PRODUCT: {
      return RD.map(
        (scale) => ({
          ...scale,
          fields: payload,
        }),
        state
      );
    }

    case SET_VALIDATED_PRODUCT_DETAILS: {
      return RD.map(
        (scale) => ({
          ...scale,
          fields: {
            ...scale.fields,
            value: payload,
          },
        }),
        state
      );
    }

    default:
      return state;
  }
};

export const getOptions = compose(RD.withDefault([]), RD.map(prop("options")));

export const getFields = compose(RD.withDefault(null), RD.map(prop("fields")));

export default reducer;
