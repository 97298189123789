import {
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
  UTF_EVENT_TYPES,
} from "../../../utils/constants";
import { trackContentInteraction } from "../../../utils/track-utf";

export const triggerLinkTrackingEvent = (profile, position, step) => {
  trackContentInteraction({
    context: profile,
    name: UTF_EVENT_NAMES.REDIRECT_INTEGRATION,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position,
    attributes: {
      step: UTF_EVENT_POSITIONS[step],
    },
  });
};

export const { TRACK_AND_TRACE, CUSTOMER_SERVICE } = UTF_EVENT_POSITIONS;
