if (typeof window.CustomEvent !== "function") {
  window.CustomEvent = function CustomEvent(event, params) {
    const newParam = params || {
      bubbles: false,
      cancelable: false,
      detail: null,
    };

    const evt = document.createEvent("CustomEvent");

    evt.initCustomEvent(
      event,
      newParam.bubbles,
      newParam.cancelable,
      newParam.detail
    );

    return evt;
  };
}
