import { createSelector } from "reselect";
import { compose, prop } from "ramda";
import RD from "../../../utils/functional/remote-data";
import {
  POST_LEAD,
  POST_LEAD_FAILURE,
  POST_LEAD_RESET,
  POST_LEAD_SUCCESS,
} from "../../../actions";

const INITIAL_STATE = {
  gotLead: RD.notAsked(),
  uniqueId: RD.notAsked(),
};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case POST_LEAD:
      return {
        ...state,
        gotLead: RD.loading(),
        uniqueId: RD.loading(),
      };

    case POST_LEAD_SUCCESS:
      return {
        ...state,
        gotLead: RD.success(payload.businessUnits),
        uniqueId: RD.success(payload.uniqueId),
      };

    case POST_LEAD_FAILURE:
      return {
        ...state,
        gotLead: RD.error(payload),
      };

    case POST_LEAD_RESET:
      return {
        ...state,
        gotLead: RD.notAsked(),
        uniqueId: RD.notAsked(),
      };

    default:
      return state;
  }
};

export const getUniqueId = compose(RD.withDefault(null), prop("uniqueId"));

export const getIsLoadingGotLead = createSelector(prop("gotLead"), (gotLead) =>
  RD.isLoading(gotLead)
);

export const getBusThatGotLead = prop("gotLead");

export default reducer;
