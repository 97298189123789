import React, { Fragment } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";
import { Cancel as IconCancel } from "@dhl-official/icons";

// #region Component Styles
const LoadCss = createGlobalStyle`
  .PL-Modal__Content {
    background-color: var(--dui-color-white-500);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 12px 0 rgba(0, 0, 0, 0.1),
      0 0 1px 0 rgba(0, 0, 0, 0.1);
    max-height: 60vh;
    max-width: 60vw;
    outline: solid 2px transparent;
    padding: calc(var(--dui-size-space-3x) * 9) calc(var(--dui-size-space-3x) * 15);
    position: relative;
    transform: translateY(-25px);
    transition: outline-color var(--dui-duration-default), transform var(--dui-duration-default);

    &:focus {
      outline: solid 2px black;
    }

    /* Order matters here, the after-open modifier needs to come before before-close, I know, that sucks... */
    &--after-open {
      transform: translateY(0);
    }

    &--before-close {
      transform: translateY(-25px);
    }
  }

  .PL-Modal__Overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity var(--dui-duration-default);
    z-index: 99999;

    /* Order matters here, the after-open modifier needs to come before before-close */
    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: solid 2px transparent;
  box-sizing: border-box;
  color: var(--dui-color-black-400);
  cursor: pointer;
  outline: 0;
  padding: var(--dui-size-space-10x);
  position: absolute;
  right: 0;
  top: 0;
  transition: color var(--dui-duration-default),
    border-color var(--dui-duration-default);

  &:hover,
  &:focus {
    border-color: var(--dui-color-black-400);
  }

  &:hover {
    color: var(--dui-color-red-400);
  }
`;

const CloseIcon = styled(IconCancel).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  display: block;
  width: 16px;
  height: 16px;
`;
// #endregion

const Modal = ({
  aria,
  children,
  className,
  closeButtonAriaLabel,
  contentAriaLabel,
  isOpen,
  isStatic,
  onClose,
  overlayColor,
  shouldShowCloseButton,
}) => (
  <Fragment>
    <LoadCss />

    <ReactModal
      aria={aria}
      ariaHideApp={false}
      className={{
        afterOpen: "PL-Modal__Content--after-open",
        base: ["PL-Modal__Content", className].filter(Boolean).join(" "),
        beforeClose: "PL-Modal__Content--before-close",
      }}
      closeTimeoutMS={250}
      contentLabel={contentAriaLabel}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={{
        afterOpen: "PL-Modal__Overlay--after-open",
        base: "PL-Modal__Overlay",
        beforeClose: "PL-Modal__Overlay--before-close",
      }}
      preventScroll
      shouldCloseOnEsc={!isStatic}
      shouldCloseOnOverlayClick={!isStatic}
      style={{ overlay: { backgroundColor: overlayColor } }}
    >
      {shouldShowCloseButton && (
        <CloseButton aria-label={closeButtonAriaLabel} onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}

      {children}
    </ReactModal>
  </Fragment>
);

Modal.propTypes = {
  /**
   * Aria tags that can be added to further enhance the modals description and content, find more info here:
   * https://reactcommunity.org/react-modal/accessibility/
   */
  aria: PropTypes.shape({
    labelledby: PropTypes.string,
    describedby: PropTypes.string,
  }),
  /** Elements as attributed modal content */
  children: PropTypes.node.isRequired,
  /** Class to the root element */
  className: PropTypes.string,
  /**
   * Required to ensure informed decision about A11Y.
   * Aria label of the close button.
   * */
  closeButtonAriaLabel: PropTypes.string.isRequired,
  /**
   * Required to ensure informed decision about A11Y.
   * Aria label explaining the dialog.
   * */
  contentAriaLabel: PropTypes.string.isRequired,
  /** Boolean indicating and controlling the visibility of the modal */
  isOpen: PropTypes.bool,
  /**
   * Sets the Modal to static so it cannot be closed with the `esc` key or by clicking on the `overlay`.
   * Only way to close it is when the user explicitily clicks on the close icon button or any custom button
   * inside the Modal.
   * */
  isStatic: PropTypes.bool,
  /** Function handler for close event */
  onClose: PropTypes.func.isRequired,
  /** Optional css background color definition for the modal overlay */
  overlayColor: PropTypes.string,
  /**
   * Boolean indicating the visibility of the close button.
   * If button is not present, modal can be closed via `esc` key or click on overlay (unless `isStatic` is set to true)
   * */
  shouldShowCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  isStatic: false,
  shouldShowCloseButton: true,
};

export default Modal;
