import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DhlCard,
  DhlInputField,
  DhlLink,
  DhlList,
  DhlListItem,
  DhlText,
} from "@dhl-official/react-library";
import {
  Cancel as IconCancel,
  CancelCircle as IconCancelCircle,
} from "@dhl-official/icons";
import { FormattedMessage } from "react-intl";
import styled, { keyframes } from "styled-components";
import { always, propEq } from "ramda";
import FF from "../../../../utils/functional/form-field";
import {
  AB_TESTING_CAMPAIGNS,
  PROFILES,
  REGIONS,
  STYLES_CUSTOM_BREAKPOINT_CARDS,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
} from "../../../../utils/constants";
import FrequencySelector from "../FrequencySelector";
import RegionOptions from "../RegionOptions";
import { trackContentInteraction } from "../../../../utils/track-utf";
import { createAbsoluteUrl } from "../../../../utils/absolute-url";
import { getLinkTarget } from "../../../../utils/link-target";
import { isFSLikeProfile } from "../../../../utils";

// #region Component Styles
const APPEAR_FROM_TOP = keyframes`
  from {
    transform: translateY(-10px) scale(0.9);
    opacity: 0;
  }

  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

const Container = styled(DhlCard)`
  display: block;
  height: 100%;

  & .card-container {
    height: 100%;
  }

  & .card-body {
    border: solid 1px transparent;
    border-color: ${({ hasError }) =>
      hasError ? `var(--dui-color-red-500)` : undefined};
    border-radius: var(--dui-size-radius-md);
    height: 100%;
    padding-bottom: var(--dui-size-space-3x);
    padding-left: var(--dui-size-space-7x);
    padding-right: var(--dui-size-space-7x);
    padding-top: var(--dui-size-space-3x);
    position: relative;
    user-select: none;
  }
`;

const HeadlineContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: var(--dui-size-space-10x) 0 var(--dui-size-space-7x) 0;

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    flex-direction: row;
  }
`;

const ProductIcon = styled.svg.attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  background-color: var(--dui-color-gray-50);
  height: 67px;
  margin-bottom: var(--dui-size-space-3x);
  margin-top: var(--dui-size-space-10x);
  padding: 10px;
  width: 100%;

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    margin-bottom: 0;
    margin-top: 4px;
    width: 67px;
  }
`;

const Small = styled.small`
  display: block;
  font-size: 1em;
  font-weight: 400;

  :before {
    content: "(";
  }

  :after {
    content: ")";
  }
`;

const ProductLabel = styled(DhlText).attrs({ weight: 700 })`
  & span {
    display: block;
    font-size: 1.5rem;
    word-break: break-word;
  }
`;

const ProductText = styled.div`
  flex: 1;
  margin-bottom: var(--dui-size-space-7x);
  margin-left: 0;

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    margin-left: var(--dui-size-space-7x);
  }
`;

const ProductInfo = styled.div`
  ul {
    line-height: initial;
  }

  li {
    color: var(--dui-color-gray-600);
    font-size: var(--dui-size-font-sm);
    margin-bottom: 0;

    &::before {
      width: 10px;
    }

    &::after {
      background-color: var(--dui-color-gray-600);
      height: 3px;
      margin-left: 0px;
      width: 3px;
    }
  }
`;

const ProductInfoList = styled(DhlList)`
  .dhl-list {
    gap: 0;
    padding-inline-start: 15px;
  }
`;

const ProductInfoListItem = styled(DhlListItem)``;

const ProductInfoListText = styled(DhlText)`
  & span {
    font-size: 1.3rem;
  }
`;

const InputContainer = styled.div`
  margin-bottom: var(--dui-size-space-3x);
  position: relative;
  z-index: 1;
`;

const NumberOfShipmentsContainer = styled.div`
  margin-bottom: var(--dui-size-space-10x);
`;

const NumberOfShipments = styled(DhlInputField).attrs({
  autoComplete: "off",
  type: "text",
})`
  input {
    appearance: none;
    -webkit-appearance: none;
  }
`;

const ReroutingRegionNotice = styled(DhlText).attrs({
  isParagraph: true,
  size: "sm",
})`
  p {
    margin-top: var(--dui-size-space-10x);
    text-align: center;
  }
`;

const AlternativeServiceLink = styled(DhlLink).attrs({
  fontSize: "sm",
  rel: "noopener noreferrer",
})``;

const CloseButton = styled.button.attrs({ type: "button" })`
  background-color: transparent;
  border: 0;
  color: var(--dui-color-black-400);
  cursor: pointer;
  display: flex;
  margin: var(--dui-size-space-7x);
  outline-offset: 2px;
  outline: solid 2px transparent;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: outline-color var(--dui-duration-default);

  &:focus-visible {
    outline-color: var(--dui-color-black-400);
  }

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    margin: var(--dui-size-space-3x);
  }
`;

const NoMatches = styled(DhlText).attrs({
  isParagraph: true,
  size: "sm",
})`
  & p {
    animation: ${APPEAR_FROM_TOP};
    animation-duration: var(--dui-duration-default);
    margin: var(--dui-size-space-7x) 0;
    padding-left: calc(20px + var(--dui-size-space-3x));
    position: relative;
    text-align: left;
  }
`;

const NoMatchesIcon = styled(IconCancelCircle).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  color: var(--dui-color-red-500);
  height: 20px;
  left: 0;
  margin-top: 4px;
  position: absolute;
  top: 0;
  width: 20px;
`;

const CloseIcon = styled(IconCancel)`
  width: 20px;
  height: 20px;
`;
// #endregion

const triggerLinkTrackingEvent = (context, name, position) => {
  trackContentInteraction({
    context,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position,
    attributes: {
      step: UTF_EVENT_POSITIONS.PRODUCT_DETAILS,
    },
  });
};

const REROUTING_DATA_TESTIDS = {
  region: {
    link: "rerouting-shipment-product-region-link",
    notice: "rerouting-shipment-product-region-notice",
  },
  threshold: {
    link: "rerouting-shipment-product-threshold-link",
    notice: "rerouting-shipment-product-threshold-notice",
  },
};

const TRACKING_KEYS = {
  reroutingRegion:
    "regularShipmentLP.interactions.rerouting.reroutingRegionProductDetailsLinkClicked",
  reroutingThreshold:
    "regularShipmentLP.interactions.rerouting.reroutingThresholdProductDetailsLinkClicked",
};

const getHeadline = (label, options) =>
  options.length !== 1 ? (
    label
  ) : (
    <Fragment>
      {label}
      <Small>
        <FormattedMessage
          id={`FS2.CardShipmentScale.shipmentScale_${options[0]}_Only`}
        />
      </Small>
    </Fragment>
  );

const getReroutingRegionMessage = (
  reroutingRegionData,
  product,
  missingRegion,
  availableRegion
) =>
  reroutingRegionData?.[product]?.[missingRegion]
    ? `FS2.Rerouting.RegionProductDetailsWithLink_${availableRegion}`
    : `FS2.Rerouting.RegionProductDetailsWithoutLink_${availableRegion}`;

const getReroutingRegionLink = (
  reroutingRegionData,
  product,
  missingRegion,
  getMessage,
  openLinksInSameTab
) =>
  reroutingRegionData?.[product]?.[missingRegion] ? (
    <AlternativeServiceLink
      dataTestid={`${REROUTING_DATA_TESTIDS.region.link}-${product}`}
      dataTracking={`${TRACKING_KEYS.reroutingRegion}.${reroutingRegionData[product][missingRegion]}`}
      href={createAbsoluteUrl(
        getMessage(
          `FS2.Rerouting.AlternativeService_${reroutingRegionData[product][missingRegion]}_URL`
        )
      )}
      onClick={() =>
        triggerLinkTrackingEvent(
          reroutingRegionData[product][missingRegion],
          UTF_EVENT_NAMES.REGION_NOTICE,
          UTF_EVENT_POSITIONS.REROUTING_CLICK
        )
      }
      target={getLinkTarget(openLinksInSameTab)}
    >
      <FormattedMessage
        id={`FS2.Rerouting.AlternativeService_${reroutingRegionData[product][missingRegion]}_Label`}
      />
    </AlternativeServiceLink>
  ) : (
    ""
  );

const getReroutingThresholdMessage = (
  reroutingThresholdData,
  parcelOption,
  product
) => {
  const formPosition = parcelOption ? "ParcelOptions" : "Threshold";
  return reroutingThresholdData?.[product]
    ? `FS2.Rerouting.${formPosition}ProductDetailsWithLink`
    : `FS2.Rerouting.${formPosition}ProductDetailsWithoutLink`;
};

const getReroutingThresholdLink = (
  reroutingThresholdData,
  product,
  getMessage,
  openLinksInSameTab
) =>
  reroutingThresholdData?.[product] ? (
    <AlternativeServiceLink
      dataTestid={`${REROUTING_DATA_TESTIDS.threshold.link}-${product}`}
      dataTracking={`${TRACKING_KEYS.reroutingThreshold}.${reroutingThresholdData[product]}`}
      href={createAbsoluteUrl(
        getMessage(
          `FS2.Rerouting.AlternativeService_${reroutingThresholdData[product]}_URL`
        )
      )}
      onClick={() =>
        triggerLinkTrackingEvent(
          reroutingThresholdData[product],
          UTF_EVENT_NAMES.THRESHOLD_NOTICE,
          UTF_EVENT_POSITIONS.REROUTING_CLICK
        )
      }
      target={getLinkTarget(openLinksInSameTab)}
    >
      <FormattedMessage
        id={`FS2.Rerouting.AlternativeService_${reroutingThresholdData[product]}_Label`}
      />
    </AlternativeServiceLink>
  ) : (
    ""
  );

const ProductDetailsSingleCard = ({
  abTesting,
  extraOptions,
  frequency,
  frequencyOptions,
  intl,
  isRegionInvalid,
  name,
  noMatches,
  numberOfShipments,
  onRemoveProduct,
  onSetFrequency,
  onSetNumberOfShipments,
  onSetRegion,
  openLinksInSameTab,
  parcelOption,
  product,
  productIcon,
  productInfo,
  productLabel,
  profile,
  regions,
  regionOptions,
  reroutingRegionData,
  reroutingThresholdData,
  selectedRegions,
  trackingPath,
}) => {
  const [isNoMatchesTrue, setIsNoMatchesTrue] = useState(true);

  const showReroutingRegionForGlobal =
    regionOptions.length === 1 && regionOptions[0] === REGIONS.DOMESTIC;

  const showReroutingRegionForDomestic = !regionOptions.includes(
    REGIONS.DOMESTIC
  );

  const showReroutingRegionNotice =
    showReroutingRegionForGlobal || showReroutingRegionForDomestic;

  useEffect(() => {
    if (
      showReroutingRegionForDomestic &&
      reroutingRegionData?.[product]?.[REGIONS.DOMESTIC]
    ) {
      triggerLinkTrackingEvent(
        reroutingRegionData?.[product]?.[REGIONS.DOMESTIC],
        UTF_EVENT_NAMES.REGION_NOTICE,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }

    if (
      showReroutingRegionForGlobal &&
      reroutingRegionData?.[product]?.[REGIONS.GLOBAL]
    ) {
      triggerLinkTrackingEvent(
        reroutingRegionData?.[product]?.[REGIONS.GLOBAL],
        UTF_EVENT_NAMES.REGION_NOTICE,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }
  }, [showReroutingRegionNotice]);

  useEffect(() => {
    if (noMatches && isNoMatchesTrue) {
      setIsNoMatchesTrue(false);
      triggerLinkTrackingEvent(
        reroutingThresholdData?.[product],
        UTF_EVENT_NAMES.THRESHOLD_NOTICE,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }
  }, [noMatches]);

  const getMessage = (id) => intl.formatMessage({ id });

  const onRegionChange = (value) => {
    onSetRegion([product, value]);
  };

  const onFrequencyChange = (value) => {
    onSetFrequency([product, value]);
  };

  const onNumberOfShipmentsChange = (event) => {
    onSetNumberOfShipments([product, event.target.value]);
  };

  const labelNumberOfShipments = intl.formatMessage({
    id: "FS2.CardShipmentProduct.labelNumberOfShipments",
  });

  const ariaLabelCloseButton = intl.formatMessage(
    {
      id: "FS2.CardShipmentProduct.screenReaderLabel_closeButton",
    },
    {
      product: productLabel,
    }
  );

  return (
    <Container>
      {isFSLikeProfile(profile) && (
        <CloseButton
          aria-label={ariaLabelCloseButton}
          data-testid={`${name}_numberOfShipments_${product}_closeButton`}
          onClick={() => onRemoveProduct(product)}
        >
          <CloseIcon />
        </CloseButton>
      )}
      <HeadlineContainer aria-hidden="true">
        <ProductIcon as={productIcon} />
        <ProductText>
          <ProductLabel aria-hidden="true">
            {getHeadline(productLabel, regionOptions)}
          </ProductLabel>
          <ProductInfo>
            <ProductInfoList>
              {productInfo.map((i) => (
                <ProductInfoListItem key={i}>
                  <ProductInfoListText>{i}</ProductInfoListText>
                </ProductInfoListItem>
              ))}
            </ProductInfoList>
          </ProductInfo>
        </ProductText>
      </HeadlineContainer>

      <InputContainer>
        <NumberOfShipmentsContainer>
          <NumberOfShipments
            dataAriaLabel={`${productLabel}, ${labelNumberOfShipments}`}
            dataTestid={`shipmentProduct_${product}_numberOfShipments`}
            data-tracking={`${trackingPath}.numberOfShipments`}
            dataId={`shipmentProduct_${product}_numberOfShipments`}
            isRequired
            name={`shipmentProduct_${product}_numberOfShipments`}
            onDhlInput={onNumberOfShipmentsChange}
            value={FF.getValue(numberOfShipments)}
            validation={FF.case(
              {
                valid: () => ({
                  type: "valid",
                }),

                invalid: (_, { feedbackMessageId, rule }) => ({
                  type: "invalid",
                  message: intl.formatMessage(
                    { id: feedbackMessageId },
                    {
                      minValue: rule.min,
                      maxValue: rule.max,
                    }
                  ),
                }),

                _: always(undefined),
              },
              numberOfShipments
            )}
            variant={{
              label: labelNumberOfShipments,
              placeholder: labelNumberOfShipments,
              type: "static",
            }}
          />
        </NumberOfShipmentsContainer>

        <FrequencySelector
          intl={intl}
          isInvalid={FF.isInvalid(frequency)}
          options={frequencyOptions}
          product={product}
          selectedFrequency={FF.getValue(frequency)}
          setSelectedFrequency={onFrequencyChange}
          trackingPath={trackingPath}
          validationFeedback={FF.getFeedback(frequency)}
        />

        {regionOptions.length > 1 && (
          <RegionOptions
            intl={intl}
            isInvalid={isRegionInvalid}
            onChange={onRegionChange}
            options={regionOptions}
            product={product}
            regions={regions}
            selectedRegions={selectedRegions}
            trackingPath={trackingPath}
          />
        )}

        {extraOptions}

        {noMatches && (
          <NoMatches
            dataTestid={`${REROUTING_DATA_TESTIDS.threshold.notice}-${product}`}
          >
            <NoMatchesIcon />
            {abTesting === AB_TESTING_CAMPAIGNS.REROUTING_THRESHOLD_COPY ? (
              <React.Fragment>
                Your submission does not meet our minimum threshold for a{" "}
                {productLabel} business account. For less frequent shipments,
                continue to{" "}
                {getReroutingThresholdLink(
                  reroutingThresholdData,
                  product,
                  getMessage,
                  openLinksInSameTab
                )}
              </React.Fragment>
            ) : (
              <FormattedMessage
                id={getReroutingThresholdMessage(
                  reroutingThresholdData,
                  parcelOption,
                  product
                )}
                values={{
                  alternativeService: getReroutingThresholdLink(
                    reroutingThresholdData,
                    product,
                    getMessage,
                    openLinksInSameTab
                  ),
                  parcelOption: parcelOption
                    ? intl.formatMessage({
                        id: `FS2.Rerouting.ParcelOptionsProductDetails${parcelOption
                          .charAt(0)
                          .toUpperCase()}${parcelOption.slice(1)}`,
                      })
                    : null,
                  product: productLabel,
                  thresholds: [
                    intl
                      .formatMessage(
                        {
                          id: "FS2.CardShipmentProduct.frequencyWithShipment",
                        },
                        {
                          numberOfShipments: noMatches.threshold.find(
                            propEq("timeInterval", FF.getValue(frequency))
                          ).numberOfShipments,
                          frequency: intl.formatMessage({
                            id: `FS2.CardShipmentProduct.frequencyOptionLabel_${FF.getValue(
                              frequency
                            )}`,
                          }),
                        }
                      )
                      .toLowerCase(),
                  ].join(" "),
                }}
              />
            )}
          </NoMatches>
        )}

        {showReroutingRegionNotice && !noMatches && (
          <ReroutingRegionNotice
            dataTestid={`${REROUTING_DATA_TESTIDS.region.notice}-${product}`}
          >
            {showReroutingRegionForGlobal && (
              <FormattedMessage
                id={getReroutingRegionMessage(
                  reroutingRegionData,
                  product,
                  REGIONS.GLOBAL,
                  REGIONS.DOMESTIC
                )}
                values={{
                  alternativeService: getReroutingRegionLink(
                    reroutingRegionData,
                    product,
                    REGIONS.GLOBAL,
                    getMessage,
                    openLinksInSameTab
                  ),
                  product: productLabel,
                }}
              />
            )}
            {showReroutingRegionForDomestic && (
              <FormattedMessage
                id={getReroutingRegionMessage(
                  reroutingRegionData,
                  product,
                  REGIONS.DOMESTIC,
                  REGIONS.GLOBAL
                )}
                values={{
                  alternativeService: getReroutingRegionLink(
                    reroutingRegionData,
                    product,
                    REGIONS.DOMESTIC,
                    getMessage,
                    openLinksInSameTab
                  ),
                  product: productLabel,
                }}
              />
            )}
          </ReroutingRegionNotice>
        )}
      </InputContainer>
    </Container>
  );
};

ProductDetailsSingleCard.propTypes = {
  abTesting: PropTypes.string,
  extraOptions: PropTypes.object,
  frequency: PropTypes.object,
  frequencyOptions: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  isRegionInvalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noMatches: PropTypes.object,
  numberOfShipments: PropTypes.object,
  onRemoveProduct: PropTypes.func.isRequired,
  onSetFrequency: PropTypes.func.isRequired,
  onSetNumberOfShipments: PropTypes.func.isRequired,
  onSetRegion: PropTypes.func.isRequired,
  openLinksInSameTab: PropTypes.bool,
  parcelOption: PropTypes.string,
  product: PropTypes.string.isRequired,
  productIcon: PropTypes.elementType.isRequired,
  productInfo: PropTypes.array.isRequired,
  productLabel: PropTypes.string.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  regions: PropTypes.object,
  regionOptions: PropTypes.array.isRequired,
  reroutingRegionData: PropTypes.object,
  reroutingThresholdData: PropTypes.object,
  selectedRegions: PropTypes.array.isRequired,
  trackingPath: PropTypes.string.isRequired,
};

export default ProductDetailsSingleCard;
