import React from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";

/**
 * Renders children only if all translations are present.
 * As an input uses an array of locale keys or gets them from messages object or both.
 */
const LocalizedContainer = ({ children, keys = [], messages = {} }) => {
  const intl = useIntl();
  const messageIds = messages
    ? Object.keys(messages).map((key) => messages[key].id)
    : [];

  const hasMissingLocalization = []
    .concat(keys)
    .concat(messageIds)
    .some((localeId) => !intl.messages[localeId]);

  return hasMissingLocalization ? null : (
    <React.Fragment>{children}</React.Fragment>
  );
};

LocalizedContainer.propTypes = {
  children: PropTypes.node.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string),
  messages: PropTypes.any,
};

export default LocalizedContainer;
