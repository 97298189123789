import React, { memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getLinkTarget } from "../../../utils/link-target";
import { useOpenLinksInSameTab } from "../../../hooks/useOpenLinksInSameTab";
import messages from "./FooterRedirectLinks.messages";
import {
  Container,
  Headline,
  RedirectionMessage,
  Link,
} from "./FooterRedirectLinks.styled";
import {
  triggerLinkTrackingEvent,
  TRACK_AND_TRACE,
  CUSTOMER_SERVICE,
} from "./FooterRedirectLinks.analytics";
import { useProfile } from "../../../hooks/useProfile";
import { useStep } from "../../../hooks/useStep";
import LocalizedContainer from "../../atoms/LocalizedContainer";
import { linksLocaleKey } from "./FooterRedirectLinks.constants";

const FooterRedirectLinks = () => {
  const intl = useIntl();
  const headline = intl.formatMessage(messages.headline);
  const openLinksInSameTab = useOpenLinksInSameTab();
  const profile = useProfile();
  const step = useStep();

  return (
    <LocalizedContainer keys={[linksLocaleKey]}>
      <Container>
        <Headline>{headline}</Headline>

        <RedirectionMessage>
          <FormattedMessage
            id={linksLocaleKey}
            values={{
              trackAndTrace: (
                <Link
                  dataTestid="track-and-trace-link"
                  href={intl.formatMessage(messages.TrackAndTrace_URL)}
                  onClick={() =>
                    triggerLinkTrackingEvent(profile, TRACK_AND_TRACE, step)
                  }
                  target={getLinkTarget(openLinksInSameTab)}
                >
                  {intl.formatMessage(messages.TrackAndTrace_Label)}
                </Link>
              ),
              customerService: (
                <Link
                  dataTestid="customer-service-link"
                  href={intl.formatMessage(messages.CustomerService_URL)}
                  onClick={() =>
                    triggerLinkTrackingEvent(profile, CUSTOMER_SERVICE, step)
                  }
                  target={getLinkTarget(openLinksInSameTab)}
                >
                  {intl.formatMessage(messages.CustomerService_Label)}
                </Link>
              ),
            }}
          />
        </RedirectionMessage>
      </Container>
    </LocalizedContainer>
  );
};

export default memo(FooterRedirectLinks);
