import { identity } from "ramda";
import { DEFAULT_PROFILE } from "../../../utils/constants";

const INITIAL_STATE = DEFAULT_PROFILE;

const reducer = (profile = INITIAL_STATE) => () => profile;

export const get = identity;

export default reducer;
