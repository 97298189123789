import { memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { compose } from "ramda";
import ShipmentProductCard from ".";
import * as actions from "../../../../reducers/actions";

const mapDispatchToProps = {
  onRemoveProduct: actions.removeShipmentProductEffect,
  onSetFrequency: actions.setProductFrequency,
  onSetNumberOfShipments: actions.setProductNumberOfShipments,
  onSetRegion: actions.setProductRegion,
};

export default compose(
  memo,
  connect(null, mapDispatchToProps),
  injectIntl
)(ShipmentProductCard);
