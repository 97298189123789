import {
  INIT_SCHEDULER_FROM_PROFILE,
  SET_SCHEDULER_DAY,
  SET_SCHEDULER_SLOT,
  SET_SCHEDULER_DISPLAY,
} from "../../../../actions";

export const setSchedulerDay = (day, businessUnit) => ({
  payload: {
    businessUnit,
    day,
  },
  type: SET_SCHEDULER_DAY,
});

export const setSchedulerSlot = (slot, businessUnit) => ({
  payload: {
    businessUnit,
    slot,
  },
  type: SET_SCHEDULER_SLOT,
});

export const setSchedulerDisplay = (payload) => ({
  payload,
  type: SET_SCHEDULER_DISPLAY,
});

export const initSchedulerFromProfile = (payload) => ({
  payload,
  type: INIT_SCHEDULER_FROM_PROFILE,
});

export const setSchedulerDisplayEffect = (payload) => (dispatch) => {
  dispatch(setSchedulerDisplay(payload));
};

export const initSchedulerFromProfileEffect = (profile) => (dispatch) => {
  dispatch(initSchedulerFromProfile(profile));
};
