import { useSelector } from "react-redux";
import { STEPS } from "../utils/constants";

export const useStep = () => {
  return (
    useSelector((state) => state?.ui?.steps?.stepsHistory?.slice(-1)?.[0]) ??
    STEPS.PRODUCT
  );
};

export default useStep;
