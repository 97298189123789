import { css } from "styled-components";

const getFlagForCountry = (country) => {
  switch (country) {
    case "AD":
      return css`
        background-position: -43px -6px;
      `;

    case "AE":
      return css`
        background-position: -464px -216px;
      `;

    case "AF":
      return css`
        background-position: -1px -48px;
      `;

    case "AG":
      return css`
        background-position: -43px -48px;
      `;

    case "AI":
      return css`
        background-position: -85px -6px;
      `;

    case "AL":
      return css`
        background-position: -85px -48px;
      `;

    case "AM":
      return css`
        background-position: -1px -90px;
      `;

    case "AN":
      return css`
        background-position: -43px -90px;
      `;

    case "AO":
      return css`
        background-position: -85px -90px;
      `;

    case "AQ":
      return css`
        background-position: -127px -6px;
      `;

    case "AR":
      return css`
        background-position: -127px -48px;
      `;

    case "AS":
      return css`
        background-position: -127px -90px;
      `;

    case "AT":
      return css`
        background-position: -1px -132px;
      `;

    case "AU":
      return css`
        background-position: -43px -132px;
      `;

    case "AW":
      return css`
        background-position: -85px -132px;
      `;

    case "AZ":
      return css`
        background-position: -169px -6px;
      `;

    case "BA":
      return css`
        background-position: -169px -48px;
      `;

    case "BB":
      return css`
        background-position: -169px -90px;
      `;

    case "BD":
      return css`
        background-position: -169px -132px;
      `;

    case "BE":
      return css`
        background-position: -1px -174px;
      `;

    case "BF":
      return css`
        background-position: -43px -174px;
      `;

    case "BG":
      return css`
        background-position: -85px -174px;
      `;

    case "BH":
      return css`
        background-position: -127px -174px;
      `;

    case "BI":
      return css`
        background-position: -169px -174px;
      `;

    case "BJ":
      return css`
        background-position: -211px -6px;
      `;

    case "BL":
      return css`
        background-position: -211px -48px;
      `;

    case "BM":
      return css`
        background-position: -211px -90px;
      `;

    case "BN":
      return css`
        background-position: -211px -132px;
      `;

    case "BO":
      return css`
        background-position: -211px -174px;
      `;

    case "BR":
      return css`
        background-position: -1px -216px;
      `;

    case "BS":
      return css`
        background-position: -43px -216px;
      `;

    case "BT":
      return css`
        background-position: -85px -216px;
      `;

    case "BW":
      return css`
        background-position: -127px -216px;
      `;

    case "BY":
      return css`
        background-position: -169px -216px;
      `;

    case "BZ":
      return css`
        background-position: -211px -216px;
      `;

    case "CA":
      return css`
        background-position: -253px -6px;
      `;

    case "CC":
      return css`
        background-position: -253px -48px;
      `;

    case "CD":
      return css`
        background-position: -253px -90px;
      `;

    case "CF":
      return css`
        background-position: -253px -132px;
      `;

    case "CG":
      return css`
        background-position: -253px -174px;
      `;

    case "CH":
      return css`
        background-position: -253px -216px;
      `;

    case "CI":
      return css`
        background-position: -1px -258px;
      `;

    case "CK":
      return css`
        background-position: -43px -258px;
      `;

    case "CL":
      return css`
        background-position: -85px -258px;
      `;

    case "CM":
      return css`
        background-position: -127px -258px;
      `;

    case "CN":
      return css`
        background-position: -169px -258px;
      `;

    case "CO":
      return css`
        background-position: -211px -258px;
      `;

    case "CR":
      return css`
        background-position: -253px -258px;
      `;

    case "CU":
      return css`
        background-position: -295px -6px;
      `;

    case "CV":
      return css`
        background-position: -295px -48px;
      `;

    case "CW":
      return css`
        background-position: -295px -90px;
      `;

    case "CX":
      return css`
        background-position: -295px -132px;
      `;

    case "CY":
      return css`
        background-position: -295px -174px;
      `;

    case "CZ":
      return css`
        background-position: -295px -216px;
      `;

    case "DE":
      return css`
        background-position: -295px -258px;
      `;

    case "DJ":
      return css`
        background-position: -1px -300px;
      `;

    case "DK":
      return css`
        background-position: -43px -300px;
      `;

    case "DM":
      return css`
        background-position: -85px -300px;
      `;

    case "DO":
      return css`
        background-position: -127px -300px;
      `;

    case "DZ":
      return css`
        background-position: -169px -300px;
      `;

    case "EC":
      return css`
        background-position: -211px -300px;
      `;

    case "EE":
      return css`
        background-position: -253px -300px;
      `;

    case "EG":
      return css`
        background-position: -295px -300px;
      `;

    case "EH":
      return css`
        background-position: -337px -6px;
      `;

    case "ER":
      return css`
        background-position: -337px -48px;
      `;

    case "ES":
      return css`
        background-position: -337px -90px;
      `;

    case "ET":
      return css`
        background-position: -337px -132px;
      `;

    case "EU":
      return css`
        background-position: -337px -174px;
      `;

    case "FI":
      return css`
        background-position: -337px -216px;
      `;

    case "FJ":
      return css`
        background-position: -337px -258px;
      `;

    case "FK":
      return css`
        background-position: -337px -300px;
      `;

    case "FM":
      return css`
        background-position: -1px -342px;
      `;

    case "FO":
      return css`
        background-position: -43px -342px;
      `;

    case "FR":
      return css`
        background-position: -85px -342px;
      `;

    case "GA":
      return css`
        background-position: -127px -342px;
      `;

    case "GB":
      return css`
        background-position: -169px -342px;
      `;

    case "GD":
      return css`
        background-position: -211px -342px;
      `;

    case "GE":
      return css`
        background-position: -253px -342px;
      `;

    case "GF":
      return css`
        background-position: -295px -342px;
      `;

    case "GG":
      return css`
        background-position: -337px -342px;
      `;

    case "GH":
      return css`
        background-position: -379px -6px;
      `;

    case "GI":
      return css`
        background-position: -379px -48px;
      `;

    case "GL":
      return css`
        background-position: -379px -90px;
      `;

    case "GM":
      return css`
        background-position: -379px -132px;
      `;

    case "GN":
      return css`
        background-position: -379px -174px;
      `;

    case "GP":
      return css`
        background-position: -379px -216px;
      `;

    case "GQ":
      return css`
        background-position: -379px -258px;
      `;

    case "GR":
      return css`
        background-position: -379px -300px;
      `;

    case "GS":
      return css`
        background-position: -379px -342px;
      `;

    case "GT":
      return css`
        background-position: -1px -384px;
      `;

    case "GU":
      return css`
        background-position: -43px -384px;
      `;

    case "GW":
      return css`
        background-position: -85px -384px;
      `;

    case "GY":
      return css`
        background-position: -127px -384px;
      `;

    case "HK":
      return css`
        background-position: -169px -384px;
      `;

    case "HN":
      return css`
        background-position: -211px -384px;
      `;

    case "HR":
      return css`
        background-position: -253px -384px;
      `;

    case "HT":
      return css`
        background-position: -295px -384px;
      `;

    case "HU":
      return css`
        background-position: -337px -384px;
      `;

    case "IC":
      return css`
        background-position: -379px -384px;
      `;

    case "ID":
      return css`
        background-position: -421px -6px;
      `;

    case "IE":
      return css`
        background-position: -421px -48px;
      `;

    case "IL":
      return css`
        background-position: -421px -90px;
      `;

    case "IM":
      return css`
        background-position: -421px -132px;
      `;

    case "IN":
      return css`
        background-position: -421px -174px;
      `;

    case "IQ":
      return css`
        background-position: -421px -216px;
      `;

    case "IR":
      return css`
        background-position: -421px -258px;
      `;

    case "IS":
      return css`
        background-position: -421px -300px;
      `;

    case "IT":
      return css`
        background-position: -421px -342px;
      `;

    case "JE":
      return css`
        background-position: -421px -384px;
      `;

    case "JM":
      return css`
        background-position: -1px -426px;
      `;

    case "JO":
      return css`
        background-position: -43px -426px;
      `;

    case "JP":
      return css`
        background-position: -85px -426px;
      `;

    case "KE":
      return css`
        background-position: -127px -426px;
      `;

    case "KG":
      return css`
        background-position: -169px -426px;
      `;

    case "KH":
      return css`
        background-position: -211px -426px;
      `;

    case "KI":
      return css`
        background-position: -253px -426px;
      `;

    case "KM":
      return css`
        background-position: -295px -426px;
      `;

    case "KN":
      return css`
        background-position: -337px -426px;
      `;

    case "KP":
      return css`
        background-position: -379px -426px;
      `;

    case "KR":
      return css`
        background-position: -421px -426px;
      `;

    case "KV":
      return css`
        background-position: -464px -6px;
      `;

    case "KW":
      return css`
        background-position: -464px -48px;
      `;

    case "KY":
      return css`
        background-position: -464px -90px;
      `;

    case "KZ":
      return css`
        background-position: -464px -132px;
      `;

    case "LA":
      return css`
        background-position: -464px -174px;
      `;

    case "LB":
      return css`
        background-position: -1px -6px;
      `;

    case "LC":
      return css`
        background-position: -464px -258px;
      `;

    case "LI":
      return css`
        background-position: -464px -300px;
      `;

    case "LK":
      return css`
        background-position: -464px -342px;
      `;

    case "LR":
      return css`
        background-position: -464px -384px;
      `;

    case "LS":
      return css`
        background-position: -464px -426px;
      `;

    case "LT":
      return css`
        background-position: -1px -468px;
      `;

    case "LU":
      return css`
        background-position: -43px -468px;
      `;

    case "LV":
      return css`
        background-position: -85px -468px;
      `;

    case "LY":
      return css`
        background-position: -127px -468px;
      `;

    case "MA":
      return css`
        background-position: -169px -468px;
      `;

    case "MC":
      return css`
        background-position: -211px -468px;
      `;

    case "MD":
      return css`
        background-position: -253px -468px;
      `;

    case "ME":
      return css`
        background-position: -295px -468px;
      `;

    case "MF":
      return css`
        background-position: -337px -468px;
      `;

    case "MG":
      return css`
        background-position: -379px -468px;
      `;

    case "MH":
      return css`
        background-position: -421px -468px;
      `;

    case "MK":
      return css`
        background-position: -464px -468px;
      `;

    case "ML":
      return css`
        background-position: -505px -6px;
      `;

    case "MM":
      return css`
        background-position: -505px -48px;
      `;

    case "MN":
      return css`
        background-position: -505px -90px;
      `;

    case "MO":
      return css`
        background-position: -505px -132px;
      `;

    case "MP":
      return css`
        background-position: -505px -174px;
      `;

    case "MQ":
      return css`
        background-position: -505px -216px;
      `;

    case "MR":
      return css`
        background-position: -505px -258px;
      `;

    case "MS":
      return css`
        background-position: -505px -300px;
      `;

    case "MT":
      return css`
        background-position: -505px -342px;
      `;

    case "MU":
      return css`
        background-position: -505px -384px;
      `;

    case "MV":
      return css`
        background-position: -505px -426px;
      `;

    case "MW":
      return css`
        background-position: -505px -468px;
      `;

    case "MX":
      return css`
        background-position: -1px -510px;
      `;

    case "MY":
      return css`
        background-position: -43px -510px;
      `;

    case "MZ":
      return css`
        background-position: -85px -510px;
      `;

    case "NA":
      return css`
        background-position: -127px -510px;
      `;

    case "NC":
      return css`
        background-position: -169px -510px;
      `;

    case "NE":
      return css`
        background-position: -211px -510px;
      `;

    case "NF":
      return css`
        background-position: -253px -510px;
      `;

    case "NG":
      return css`
        background-position: -295px -510px;
      `;

    case "NI":
      return css`
        background-position: -337px -510px;
      `;

    case "NL":
      return css`
        background-position: -379px -510px;
      `;

    case "BV":
    case "SJ":
    case "NO":
      return css`
        background-position: -421px -510px;
      `;

    case "NP":
      return css`
        --s: 0.5;
        background-position: -464px -506px;
        border: 0;
        height: calc(54px * var(--s));
      `;

    case "NR":
      return css`
        background-position: -505px -510px;
      `;

    case "NU":
      return css`
        background-position: -547px -6px;
      `;

    case "NZ":
      return css`
        background-position: -547px -48px;
      `;

    case "OM":
      return css`
        background-position: -547px -90px;
      `;

    case "PA":
      return css`
        background-position: -547px -132px;
      `;

    case "PE":
      return css`
        background-position: -547px -174px;
      `;

    case "PF":
      return css`
        background-position: -547px -216px;
      `;

    case "PG":
      return css`
        background-position: -547px -258px;
      `;

    case "PH":
      return css`
        background-position: -547px -300px;
      `;

    case "PK":
      return css`
        background-position: -547px -342px;
      `;

    case "PL":
      return css`
        background-position: -547px -384px;
      `;

    case "PN":
      return css`
        background-position: -547px -426px;
      `;

    case "PR":
      return css`
        background-position: -547px -468px;
      `;

    case "PS":
      return css`
        background-position: -547px -510px;
      `;

    case "PT":
      return css`
        background-position: -1px -552px;
      `;

    case "PW":
      return css`
        background-position: -43px -552px;
      `;

    case "PY":
      return css`
        background-position: -85px -552px;
      `;

    case "QA":
      return css`
        background-position: -127px -552px;
      `;

    case "RE":
      return css`
        background-position: -169px -552px;
      `;

    case "RO":
      return css`
        background-position: -211px -552px;
      `;

    case "RS":
      return css`
        background-position: -253px -552px;
      `;

    case "RU":
      return css`
        background-position: -295px -552px;
      `;

    case "RW":
      return css`
        background-position: -337px -552px;
      `;

    case "SA":
      return css`
        background-position: -379px -552px;
      `;

    case "SB":
      return css`
        background-position: -421px -552px;
      `;

    case "SC":
      return css`
        background-position: -464px -552px;
      `;

    case "SD":
      return css`
        background-position: -505px -552px;
      `;

    case "SE":
      return css`
        background-position: -547px -552px;
      `;

    case "SG":
      return css`
        background-position: -589px -6px;
      `;

    case "SH":
      return css`
        background-position: -589px -48px;
      `;

    case "SI":
      return css`
        background-position: -589px -90px;
      `;

    case "SK":
      return css`
        background-position: -589px -132px;
      `;

    case "SL":
      return css`
        background-position: -589px -174px;
      `;

    case "SM":
      return css`
        background-position: -589px -216px;
      `;

    case "SN":
      return css`
        background-position: -589px -258px;
      `;

    case "SO":
      return css`
        background-position: -589px -300px;
      `;

    case "SR":
      return css`
        background-position: -589px -342px;
      `;

    case "SS":
      return css`
        background-position: -589px -384px;
      `;

    case "ST":
      return css`
        background-position: -589px -426px;
      `;

    case "SV":
      return css`
        background-position: -589px -468px;
      `;

    case "SY":
      return css`
        background-position: -589px -510px;
      `;

    case "SZ":
      return css`
        background-position: -589px -552px;
      `;

    case "TC":
      return css`
        background-position: -1px -594px;
      `;

    case "TD":
      return css`
        background-position: -43px -594px;
      `;

    case "TF":
      return css`
        background-position: -85px -594px;
      `;

    case "TG":
      return css`
        background-position: -127px -594px;
      `;

    case "TH":
      return css`
        background-position: -169px -594px;
      `;

    case "TJ":
      return css`
        background-position: -211px -594px;
      `;

    case "TK":
      return css`
        background-position: -253px -594px;
      `;

    case "TL":
      return css`
        background-position: -295px -594px;
      `;

    case "TM":
      return css`
        background-position: -337px -594px;
      `;

    case "TN":
      return css`
        background-position: -379px -594px;
      `;

    case "TO":
      return css`
        background-position: -421px -594px;
      `;

    case "TR":
      return css`
        background-position: -464px -594px;
      `;

    case "TT":
      return css`
        background-position: -505px -594px;
      `;

    case "TV":
      return css`
        background-position: -547px -594px;
      `;

    case "TW":
      return css`
        background-position: -589px -594px;
      `;

    case "TZ":
      return css`
        background-position: -631px -6px;
      `;

    case "UA":
      return css`
        background-position: -631px -48px;
      `;

    case "UG":
      return css`
        background-position: -631px -90px;
      `;

    case "US":
      return css`
        background-position: -631px -132px;
      `;

    case "UY":
      return css`
        background-position: -631px -174px;
      `;

    case "UZ":
      return css`
        background-position: -631px -216px;
      `;

    case "VA":
      return css`
        background-position: -631px -258px;
      `;

    case "VC":
      return css`
        background-position: -631px -300px;
      `;

    case "VE":
      return css`
        background-position: -631px -342px;
      `;

    case "VG":
      return css`
        background-position: -631px -384px;
      `;

    case "VI":
      return css`
        background-position: -631px -426px;
      `;

    case "VN":
      return css`
        background-position: -631px -468px;
      `;

    case "VU":
      return css`
        background-position: -631px -510px;
      `;

    case "WF":
      return css`
        background-position: -631px -552px;
      `;

    case "WS":
      return css`
        background-position: -631px -594px;
      `;

    case "XB":
      return css`
        background-position: -1px -636px;
      `;

    case "XC":
      return css`
        background-position: -43px -636px;
      `;

    case "XE":
      return css`
        background-position: -85px -636px;
      `;

    case "XM":
      return css`
        background-position: -127px -636px;
      `;

    case "XN":
      return css`
        background-position: -169px -636px;
      `;

    case "XY":
      return css`
        background-position: -211px -636px;
      `;

    case "YE":
      return css`
        background-position: -253px -636px;
      `;

    case "YT":
      return css`
        background-position: -295px -636px;
      `;

    case "ZA":
      return css`
        background-position: -337px -636px;
      `;

    case "ZM":
      return css`
        background-position: -379px -636px;
      `;

    case "ZW":
      return css`
        background-position: -421px -636px;
      `;

    default:
      return css`
        background-position: -464px -636px;
        background-color: #d1d6da;
      `;
  }
};

export default getFlagForCountry;
