import { always, propEq } from "ramda";
import {
  REMOVE_SHIPMENT_PRODUCT,
  SET_PRODUCT_FREQUENCY,
  SET_PRODUCT_NUMBER,
  SET_PRODUCT_REGION,
  SET_PRODUCT_URGENCY,
  SET_SHIPMENT_PRODUCT,
  SET_VALIDATED_SHIPMENT_PRODUCT,
  SET_VALIDATED_PRODUCT_DETAILS,
  VALIDATE_PRODUCT_NUMBER,
} from "../../../../actions";
import FF from "../../../../utils/functional/form-field";
import * as reducers from "../../..";

const removeShipmentProduct = (id, isLastCheckedProduct) => ({
  payload: { id, isLastCheckedProduct },
  type: REMOVE_SHIPMENT_PRODUCT,
});

const setValidatedShipmentProduct = (payload) => ({
  payload,
  type: SET_VALIDATED_SHIPMENT_PRODUCT,
});

const setValidatedProductDetails = (payload) => ({
  payload,
  type: SET_VALIDATED_PRODUCT_DETAILS,
});

export const setShipmentProduct = (payload) => ({
  payload,
  type: SET_SHIPMENT_PRODUCT,
});

export const removeShipmentProductEffect = (payload) => (
  dispatch,
  getState
) => {
  const products = reducers.getProductFields(getState());
  const checkedProducts = Object.values(FF.getValue(products)).filter(
    propEq("checked", true)
  );

  dispatch(removeShipmentProduct(payload, checkedProducts.length === 1));
};

export const setProductFrequency = (payload) => ({
  payload,
  type: SET_PRODUCT_FREQUENCY,
});

export const setProductNumberOfShipments = (payload) => ({
  payload,
  type: SET_PRODUCT_NUMBER,
});

export const setProductRegion = (payload) => ({
  payload,
  type: SET_PRODUCT_REGION,
});

export const validateNumberOfShipments = () => ({
  type: VALIDATE_PRODUCT_NUMBER,
});

export const setProductUrgency = (payload) => ({
  payload,
  type: SET_PRODUCT_URGENCY,
});

export const validateShipmentProductEffect = () => (
  dispatch,
  getState,
  { document }
) => {
  const fieldSet = reducers.getProductFields(getState());
  const isValid = FF.isValid(fieldSet);

  dispatch(setValidatedShipmentProduct(FF.validate(fieldSet)));

  if (!isValid) {
    const { id } = FF.getMeta(fieldSet);
    document.querySelector(`[name='${id}']`)?.focus();
  }

  return isValid;
};

export const validateProductDetailsEffect = () => (dispatch, getState) => {
  const fieldSet = reducers.getProductFields(getState());
  const shouldShowOptions = reducers.getShouldShowOptions(getState());
  const validatedProductDetails = Object.entries(FF.getValue(fieldSet)).reduce(
    (form, [id, product]) => ({
      ...form,
      [id]: product.checked
        ? {
            ...product,
            frequency: FF.validate(product.frequency),
            numberOfShipments: FF.validate(product.numberOfShipments),
            regions: FF.validate(product.regions),
            options:
              product.options && shouldShowOptions
                ? FF.validate(product.options)
                : product.options,
          }
        : { ...product },
    }),
    {}
  );
  dispatch(setValidatedProductDetails(validatedProductDetails));
  const areProductDetailsValid =
    FF.isValid(fieldSet) &&
    FF.case(
      {
        valid: (products) =>
          !Object.values(products).some(
            (product) =>
              product.checked &&
              (!FF.isValid(product.numberOfShipments) ||
                !FF.isValid(product.regions) ||
                !FF.isValid(product.frequency) ||
                (product.options &&
                  shouldShowOptions &&
                  !FF.isValid(product.options)))
          ),
        _: always(false),
      },
      fieldSet
    );

  return areProductDetailsValid;
};
